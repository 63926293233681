import './App.scss';
import NavBar from './components/NavBar';
import BasicHome from './pages/basicHome';
// import EngHome from './pages/engHome';
// import NMOSRouting from './pages/NmosRouting';
// import MobileMagnum from './pages/mobileMagnum';
// import RadioStreamsPage from './pages/RadioStreamPage';
// import IntercomPage from './pages/IntercomPage';
// import IntercomBoleroPage from './pages/IntercomBolero';
import { useEffect, useState, useMemo, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastNotification } from '@carbon/react';
import { Theme } from '@carbon/react';
// import AdminPage from './pages/AdminPage';
// import AdminControlProjectButtons from './components/Admin/AdminControlProjectButtons';
// import IntercomKeyPanelPage from './pages/IntercomKeyPanel';
import XMLParser from 'react-xml-parser';
import { v4 as uuid } from 'uuid';
// import RokuControlOne from './pages/RokuControlOne';
// import RokuControlTwo from './pages/RokuControlTwo';
// import { fetchAllConferences, fetchAllDevices, fetchAllIFBs } from "./Utils/Intercom/fetchRRCS";
// import IntercomConferenceBlacklistEditor from './pages/IntercomConferenceBlacklistEditor';
// import DantePage from './pages/DantePage';
import { loginRequest } from './authConfig.js';
import { useMsal } from "@azure/msal-react";
import Cookies from 'js-cookie';
import UnableToAccessPage from './components/UnableTooAccessPage.js';
// import AdvancedRouting from './pages/AdvancedRouting.js';
// import Monitoring from './pages/Monitoring.js';
// import Captioning from './pages/Captioning.js';
// import Polatis from './components/Polatis/Polatis.js';
// import Salvos from './pages/Salvos.js';
// import CCUorRCPPage from './pages/CCUorRCPPage.js';
// import OmniRouting from './pages/OmniRouting.js'
// import WebSocketComponent from './pages/TestPage.js';
import Schedule from './pages/Schedule.js';
import FullScreenSchedule from './pages/FullScreenSchedule.js';
import WelcomePage from './pages/WelcomePage.js';

export default function App({ }) {

  const navigate = useNavigate()
  // const [localIP, setLocalIP] = useState(window.location.hostname?.includes("localhost") ? "lbx-lira.liberty.edu" : window.location.hostname);
  const localIP = "lbx.liberty.edu"
  const impersonationAccessLevel = "developer"
  const productionBuildVersion = "0.1.0"

  //* Mobile Magnum has a copy of this needed to be declared since the props localIP bugs out as undefined

  // useEffect(() => {
  //   if(window.location.hostname?.includes("localhost")) {
  //     // setLocalIP("lbx-lira.liberty.edu")
  //     setLocalIP("lbx-lira-dev.liberty.edu")
  //   } else {
  //     setLocalIP(window.location.hostname)
  //   }
  // }, [])

  const pathname = useLocation().pathname
  const [liraTiersFromCookie, setLiraTiersFromCookie] = useState([])
  const [appSwitcherVisible, setAppSwitcherVisible] = useState(false)
  const [side_panel_visible, setSide_panel_visible] = useState(false)
  const [settingsModalInfo, setSettingsModalInfo] = useState({
    open: false
  })
  const [selectedGlobalAction, setSelectedGlobalAction] = useState("")
  const [admin_controls, setAdmin_controls] = useState({
    "Video Feed": [],
    "Schedule": [],
    "mobile magnum": {
      0: {
        name: "Bypass",
        type: "toggle",
        toggled: false,
        toggle_on_url: `https://${localIP}/admin/magnum/disable`,
        toggle_on_endpoint: "/admin/magnum/disable",
        toggle_off_url: `https://${localIP}/admin/magnum/enable`,
        toggle_off_endpoint: "/admin/magnum/enable",
      },
      1: {
        "name": "Re-sync Mobile Magnum",
        "url": `https://${localIP}/admin/magnum/init/1`,
        "endpoint": "/admin/magnum/init/1",
        type: "button"
      }
    },
    "NMOS": {
      0: {
        name: "Bypass",
        type: "toggle",
        toggled: false,
        toggle_on_url: `https://${localIP}/admin/nmos/disable`,
        toggle_on_endpoint: "/admin/nmos/disable",
        toggle_off_url: `https://${localIP}/admin/nmos/enable`,
        toggle_off_endpoint: "/admin/nmos/enable",
      },
    },
    "Roku": {
      0: {
        "name": "Disable",
        "url": "https://",
      },
      1: {
        "name": "Enable",
        "url": "https://",
      },
    },
    "Radio": {
      0: {
        "name": "Disable",
        "url": "https://",
      },
      1: {
        "name": "Enable",
        "url": "https://",
      },
    },
    "intercom": {
      0: {
        name: "Bypass",
        type: "toggle",
        toggled: false,
        toggle_on_url: `https://${localIP}/admin/intercom/disable`,
        toggle_on_endpoint: "/admin/intercom/disable",
        toggle_off_url: `https://${localIP}/admin/intercom/enable`,
        toggle_off_endpoint: "/admin/intercom/enable",
      },
      1: {
        name: "Conference Blacklist",
        type: "link",
        endpoint: "/settings/intercom/conference-blacklist",
      },
    },
  })
  //* Mode State for Intercom Bolero Presets Page - "presets" or "keys"
  const [intercom_routing_type, setIntercom_routing_type] = useState("presets")
  const [intercomStatusSubLink, setIntercomStatusSubLink] = useState("")
  const [settings_routing_type, setSettings_routing_type] = useState("")
  const [advancedRoutesSubLink, setAdvancedRoutesSubLink] = useState("routes")
  const [ccuOrRcpSubLink, setCcuOrRcpSubLink] = useState("ccu")
  const [polatisSubLink, setPolatisSubLink] = useState("routes")
  const [captioningSubLink, setCaptioningSubLink] = useState("pcra")
  const [scheduleSubLink, setScheduleSubLink] = useState("schedule")

  const intercom_navigation_tabs = useMemo(() => ([
    {
      label: "KPs",
      path: "/intercom/kp",
      public: true,
    },
    {
      label: "PLs",
      public: false,
    },
    {
      label: "IFBs",
      public: false,
    },
    {
      label: "XPTs",
      public: false,
    },
    {
      label: "Bolero",
      path: "/intercom/bolero",
      public: true,
    },
    {
      label: "Status",
      path: "/intercom",
      public: true,
    },
  ]), []);
  const [active_navigation_tab, setActive_navigation_tab] = useState("")
  const [errors_while_routing, setErrors_while_routing] = useState([])
  const [latest_notification, setLatest_notification] = useState({
    title: "",
    subtitle: "",
    kind: "",
    current_time: "",
    color: "",
    initializer: "",
    // title: "TEST",
    // subtitle: "TEST",
    // kind: "success",
    // current_time: "",
    // color: "orange",
    // initializer: "",
  })
  const notification_element = useRef(null)

  const lockout_count_array = useMemo(() => ([]), []);
  const [lockout_user_while_applying, setLockout_user_while_applying] = useState(false)
  const [full_intercom_conferences, setFull_intercom_conferences] = useState([{
    label: "----",
    long_name: "CLEAR",
    id: 123456789,
    type: "----",
  }]);
  const [full_intercom_ifbs, setFull_intercom_ifbs] = useState([]);
  const [full_intercom_devices, setFull_intercom_devices] = useState([]);
  const [blacklisted_conferences, setBlacklisted_conferences] = useState([])
  const rrcs_localhost_check = window.location.hostname === "localhost" ? "/rrcs" : `https://${localIP}/rrcs`
  const [salvo_is_routing, setSalvo_is_routing] = useState(false)
  const [salvo_display_modal, setSalvo_display_modal] = useState(false)
  const [serverPermissions, setServerPermissions] = useState({
    'developer': {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    },
    'admin': {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    },
    engineer: {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    },
    tech: {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    },
    user: {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    },
    none: {
      admin: "none",
      captioning: "none",
      dante: "none",
      engineering: "none",
      evertz: "none",
      home: "none",
      intercom: "none",
      logs: "none",
      magnum: "none",
      monitoring: "none",
      nmos: "none",
      omni: "none",
      polatis: "none",
      radio: "none",
      roku: "none",
      salvos: "none",
      schedule: "none"
    }
  });

  const { instance } = useMsal();
  const account = instance.getActiveAccount()
  const [global_account_access_tier, setGlobal_account_access_tier] = useState(null)
  const [global_level_access_granted, setGlobal_level_access_granted] = useState(serverPermissions, setServerPermissions?.none)
  const [account_info_from_cookie, setAccount_info_from_cookie] = useState({
    name: "",
    username: "",
  })
  const [user_triggered_logout, setUser_triggered_logout] = useState(false)
  const [cookie_has_been_assigned, setCookie_has_been_assigned] = useState(false)
  const DEfAULT_USER_ACCESS = {
    admin: "none",
    captioning: "none",
    dante: "none",
    engineering: "none",
    evertz: "none",
    home: "none",
    intercom: "none",
    logs: "none",
    magnum: "none",
    nmos: "none",
    monitoring: "none",
    polatis: "none",
    radio: "none",
    roku: "none",
    schedule: "none",
    salvos: "none",
  }
  const [loggedInUserPageAccess, setLoggedInUserPageAccess] = useState({
    admin: "none",
    captioning: "none",
    dante: "none",
    engineering: "none",
    evertz: "none",
    home: "none",
    intercom: "none",
    logs: "none",
    magnum: "none",
    nmos: "none",
    monitoring: "none",
    polatis: "none",
    radio: "none",
    roku: "none",
    schedule: "none",
    salvos: "none",
  })

  useEffect(() => {
    try {
      fetch(`https://${localIP}/lira/permissions`, {
        cache: "no-cache"
      })
        .then(response => response.json())
        .then(data => {
          setServerPermissions(data)
          // console.log(data)
        })
    }
    catch (err) {
      console.log(err)
    }
  }, [])

  function whatPagesCanThisUserAccess(passedServerPermissions) {
    const serverAssignUserAccess = {}
    Object.keys(passedServerPermissions?.["developer"])?.forEach(tier => {
      serverAssignUserAccess[tier] = "none"
    })
    // console.log("FULL SERVER PERMISSIONS", passedServerPermissions)
    const cookie = Cookies.get("lbx-lira")
    try {
      const cookieData = JSON?.parse(cookie);
      // console.log("COOKIE", cookie)
      console.log(`User has access to these tiers: [${cookieData?.liraTiers}]`)
      setLiraTiersFromCookie(cookieData?.liraTiers)
      cookieData?.liraTiers?.forEach(tier => {
        // console.log("Tier Check", tier, passedServerPermissions[tier])
        Object.keys(serverPermissions[tier])?.forEach(page => {
          // console.log("PAGE", page)
          if(!serverAssignUserAccess?.hasOwnProperty(page)) {
            serverAssignUserAccess[page] = serverPermissions[tier]?.[page]?.toLowerCase()
          } else {
            if(serverAssignUserAccess[page]?.toLowerCase() === "full") return
            if(serverPermissions[tier][page]?.toLowerCase() === "none") return
            serverAssignUserAccess[page] = serverPermissions[tier]?.[page]?.toLowerCase()
          }
        })
      })
    } catch (err) {
      console.log("Failed to parse cookie", err)
      if(window.location.href.includes("localhost")) {
        console.warn("Failed to parse cookie, localhost override")
        Object.keys(passedServerPermissions?.["developer"])?.forEach(tier => {
          serverAssignUserAccess[tier] = "full"
        })
      }
      // return serverPermissions, setServerPermissions.none
    }
    console.log("calculated user access complete", serverAssignUserAccess)
    setLoggedInUserPageAccess(serverAssignUserAccess)
    return serverAssignUserAccess
  }

  async function whatGroupsDoesThisUserHave(accessToken, localOverrideAccessToken) {
    const url = 'https://graph.microsoft.com/v1.0/me/memberOf';
    const options = {
      headers: {
        Authorization: `Bearer ${localOverrideAccessToken || accessToken}`
      }
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("GROUPS", data)
      try {
        const nextPage = await fetch(data?.["@odata.nextLink"], options)
        const data2 = await nextPage.json()
        // console.log("PAGE 2", data2)
        if (data2) {
          try {
            const nextPageAgain = await fetch(data2?.["@odata.nextLink"], options)
            const data3 = await nextPageAgain.json()
            // console.log("PAGE 2", data2)
            if (data3) {
              console.log("PAGE 1 + 2 + 3 groups", [data?.value, data2?.value, data3?.value])
              return [...data?.value, ...data2?.value, ...data3?.value]
            }
          } catch (err) {
            console.log("Failed to api call the 2nd page")
          }
          console.log("PAGE 1 + 2 groups", [data?.value, data2?.value])
          return [...data?.value, ...data2?.value]
        }
      } catch (err) {
        console.log("Failed to api call the 2nd page")
      }
      console.log("PAGE 1 groups", data?.value)
      return [...data?.value];
    } catch (error) {
      console.error(`Failed to query memberOf: ${error}`);
    }
  }

  async function hasCookie(cookieName) {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
      setCookie_has_been_assigned(true)
      // console.log("COOKIE", cookie)
      const cookieData = JSON?.parse(cookie);
      // console.log("PARSED INFO", cookieData)
      const stateRes = await fetch(`https://${localIP}/lira/auth/view-state/${cookieData?.username?.split("@")[0]}`)
      const stateData = await stateRes.json()
      if(stateData) {
        fetch(`https://${localIP}/lira/auth/set-state-false/${cookieData?.username?.split("@")[0]}`)
        console.log("STATE DATA", stateData)
        Cookies.remove("lbx-lira");
        console.log("COOKIES",Cookies.get("lbx-lira"))
        setLiraTiersFromCookie([])
        return false
      }
      
      setAccount_info_from_cookie({
        name: cookieData?.name,
        username: cookieData?.username,
        liraTiers: cookieData?.liraTiers,
      });
      // console.log("name set to account usestate", cookieData?.name)
      // console.log("username set to account usestate", cookieData?.username)
      // console.log("tier set to account usestate", cookieData?.tier)
      setGlobal_account_access_tier(cookieData?.tier);
      const accountFromCookie = {
        // authorityType: cookieData["authorityType"],
        // environment: cookieData["environment"],
        homeAccountId: cookieData["homeAccountId"],
        idToken: cookieData["idToken"],
        // localAccountId: cookieData["localAccountId"],
        name: cookieData["name"],
        // nativeAccountId: cookieData["nativeAccountId"],
        tenantId: cookieData["tenantId"],
        username: cookieData["username"],
      };
      instance.setActiveAccount(accountFromCookie);
      // console.log("ACCOUNT FROM COOKIE", accountFromCookie)

      return true
    }
    console.log("No cookie found")
    return false;
  }

  function handleSendingUserToPreferredPage() {
    // window.location = `https://${localIP}/omni`;
    window.location = `https://${localIP}/`;
  }

  async function authorizationCheck(accessToken, loginResponse) {
    if (window.location.href.includes("localhost")) {

      //* Change this to test lower tier access
      setGlobal_account_access_tier(impersonationAccessLevel)
      setCookie_has_been_assigned(true)
      setAccount_info_from_cookie({
        name: "User",
        username: "dev@local",
        tier: impersonationAccessLevel,
        liraTiers: [impersonationAccessLevel, "localhost"],
      });
      console.log(`Setting user access tier to ${impersonationAccessLevel.toUpperCase()} while on LOCALHOST`)
      const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
      Cookies.set("lbx-lira", JSON.stringify({
        "username": "kflindevaldsen@liberty.edu",
        //* Single tier check (settings page)
        "tier": impersonationAccessLevel,
        //* Multiple tier check for page access
        liraTiers: [impersonationAccessLevel, "localhost"],
        "token": accessToken,
        "account": loginResponse?.account
      }), { expires: inEightHours, sameSite: "None" })
      return
    }
    const cookieStatus = hasCookie("lbx-lira");
    let localOverrideAccessToken;

    whatGroupsDoesThisUserHave(accessToken, localOverrideAccessToken)
      .then(data => {
        // console.log("GROUPS", data)
        // console.log("LOGIN RESPONSE", loginResponse)
        const group_names = []
        const liraTiers = []
        data?.forEach(group => {
          group_names?.push(group?.displayName)
          if(group?.displayName?.includes("broadcast-lira-dev")) {
            liraTiers?.push("developer")
          }
          if(group?.displayName?.includes("broadcast-lira-admin")) {
            liraTiers?.push("admin")
          }
          if(group?.displayName?.includes("broadcast-lira-engineer")) {
            liraTiers?.push("engineer")
          }
          if(group?.displayName?.includes("broadcast-lira-tech-manager")) {
            liraTiers?.push("tech")
          }
          if(group?.displayName?.includes("broadcast-lira-user")) {
            liraTiers?.push("user")
          }
          if(group?.displayName?.includes("broadcast-lira-schedule")) {
            liraTiers?.push("schedule")
          }
          if(group?.displayName?.includes("broadcast-lira-schedule-edit")) {
            liraTiers?.push("schedule-edit")
          }
          if(group?.displayName?.includes("broadcast-lira-schedule-format")) {
            liraTiers?.push("schedule-format")
          }
          if(group?.displayName?.includes("broadcast-lira-stream-selection")) {
            liraTiers?.push("stream-selection")
          }
        })
        console.log(group_names)
        // const liraGroups = group_names?.filter(group => group.includes("lira"))
        if (group_names?.includes("broadcast-lira-dev")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("developer")
          if (loginResponse) {
            // assignUserNewCookie(loginResponse?.account?.username, "developer", accessToken, JSON.stringify(loginResponse?.account))
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "developer",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-admin")) {
          // alert("you are an admin, but the login feature may not be working")
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("admin")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "admin",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-engineer")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("engineer")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "engineer",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-tech-manager")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("tech")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "tech",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-user")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("user")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            // Cookies.set("lbx-lira", `${loginResponse?.account?.username}*split-here*${"user"}*split-here*${accessToken}*split-here*${JSON.stringify(loginResponse?.account)}`, {expires: inEightHours})
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "user",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-schedule")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("schedule")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            // Cookies.set("lbx-lira", `${loginResponse?.account?.username}*split-here*${"user"}*split-here*${accessToken}*split-here*${JSON.stringify(loginResponse?.account)}`, {expires: inEightHours})
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "schedule",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-schedule-edit")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("schedule-edit")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            // Cookies.set("lbx-lira", `${loginResponse?.account?.username}*split-here*${"user"}*split-here*${accessToken}*split-here*${JSON.stringify(loginResponse?.account)}`, {expires: inEightHours})
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "schedule-edit",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-schedule-format")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("schedule-format")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            // Cookies.set("lbx-lira", `${loginResponse?.account?.username}*split-here*${"user"}*split-here*${accessToken}*split-here*${JSON.stringify(loginResponse?.account)}`, {expires: inEightHours})
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "schedule-format",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else if (group_names?.includes("broadcast-lira-stream-selection")) {
          const response = whatPagesCanThisUserAccess(serverPermissions)
          // console.log(response)
          setGlobal_level_access_granted(response)
          setGlobal_account_access_tier("stream-selection")
          if (loginResponse) {
            const inEightHours = new Date(new Date().getTime() + 480 * 60 * 1000);
            // Cookies.set("lbx-lira", `${loginResponse?.account?.username}*split-here*${"user"}*split-here*${accessToken}*split-here*${JSON.stringify(loginResponse?.account)}`, {expires: inEightHours})
            Cookies.set("lbx-lira", JSON.stringify({
              "username": loginResponse?.account?.username,
              //* Single tier check (settings page)
              "tier": "stream-selection",
              //* Multiple tier check for page access
              "liraTiers": liraTiers,
              // "token": accessToken,
              // "authorityType": loginResponse?.account?.authorityType,
              // "environment": loginResponse?.account?.environment,
              "homeAccountId": loginResponse?.account?.homeAccountId,
              "idToken": loginResponse?.account?.idToken,
              // "localAccountId": loginResponse?.account?.localAccountId,
              "name": loginResponse?.account?.name,
              // "nativeAccountId": loginResponse?.account?.nativeAccountId,
              "tenantId": loginResponse?.account?.tenantId,
            }), { expires: inEightHours })
            setCookie_has_been_assigned(true)
            handleSendingUserToPreferredPage()
          }
        } else {
          alert("You are not assigned any level of access to LIRA. Contact an admin for support")
        }
        // console.log("Tiers", liraTiers)
      })
      .catch(error => console.error(error));
  }

  useEffect(() => {
    console.log("useEffect for cookie")
    hasCookie("lbx-lira")
  }, [])

  // useEffect(() => {
  //   console.log(`As a ${global_account_access_tier} you are granted this access`, global_level_access_granted)
  // }, [global_account_access_tier, global_level_access_granted])

  const handleLoginPopup = async () => {

    //* LOCALHOST override and call authorizationCheck to acquire impersonation access 
    if (window.location.href.includes("localhost")) {
      authorizationCheck()
      return
    }

    const cookieStatus = Cookies.get("lbx-lira");
    if (!cookieStatus) {
      // handleLoginPopup();
      instance.setActiveAccount(null)
      // console.log("TRYING TO LOGIN?")

      try {
        console.log("ATTEMPT TO LOGIN WITH POPUP")
        // fallback to interaction when silent call fails
        const loginResponse = await instance.loginPopup(loginRequest);
        // const loginResponse = await instance.loginRedirect(loginRequest);
        console.log("LOGIN RESPONSE", loginResponse)
        instance.setActiveAccount(loginResponse.account);
        const account = instance.getActiveAccount()
        console.log(account)
        const accessToken = loginResponse.accessToken
        const levelAccess = authorizationCheck(accessToken, loginResponse)
        return accessToken
      } catch (err) {
        // console.error('Failed to acquire token silently', err);
      }
    }
  };

  const handleLogoutPopup = () => {
    navigate("/")
    setUser_triggered_logout(true)
    Cookies.remove("lbx-lira");
    setLiraTiersFromCookie([])
    instance.setActiveAccount(null)
    setGlobal_account_access_tier(null)

    instance.logoutPopup({
      // mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
    window.close()
  };

  function redirectIfUserIsNotAllowedHere() {
    if (window.location.href.includes("localhost")) {
      // document.location.href = `http://localhost:3000/`
      return
    }
    // else {
    // }
    document.location.href = `https://${localIP}`
    window.location.replace(`https://${localIP}`)
  }

  function send_webhook_to_teams(passed_summary, passed_title, passed_subtitle, passed_fact, passed_link, secondary_passed_link) {
    if (passed_fact === null) {
      fetch(`https://${localIP}/webhook/new-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "@type": "MessageCard",
          "@context": "http://schema.org/extensions",
          "themeColor": "0076D7",
          "summary": passed_summary,
          "sections": [{
            "activityTitle": passed_title,
            "activitySubtitle": passed_subtitle,
            "activityImage": "https://imgs.search.brave.com/8kYrX8kgVCmjBChcrrIJZuhaVFWqzNMNgGKekEhQGx4/rs:fit:560:320:1/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy90/aHVtYi9hL2E3L1Jl/YWN0LWljb24uc3Zn/LzY0MHB4LVJlYWN0/LWljb24uc3ZnLnBu/Zw",
          }],
          facts: [
            {
              name: "User",
              value: account?.username || account_info_from_cookie?.username
            }
          ],
        })
      })
    } else if (secondary_passed_link) {
      fetch(`https://${localIP}/webhook/new-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "@type": "MessageCard",
          "@context": "http://schema.org/extensions",
          "themeColor": "0076D7",
          "summary": passed_summary,
          "sections": [{
            "activityTitle": passed_title,
            "activitySubtitle": passed_subtitle,
            "activityImage": "https://imgs.search.brave.com/8kYrX8kgVCmjBChcrrIJZuhaVFWqzNMNgGKekEhQGx4/rs:fit:560:320:1/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy90/aHVtYi9hL2E3L1Jl/YWN0LWljb24uc3Zn/LzY0MHB4LVJlYWN0/LWljb24uc3ZnLnBu/Zw",
            facts: [{
              name: passed_fact?.key,
              value: passed_fact?.value,
            },
            {
              name: "Location related to error",
              value: passed_link || `https://${localIP}v.liberty.edu`,
            },
            {
              name: "Another Location related to error",
              value: secondary_passed_link,
            },
            {
              name: "User",
              value: account?.username || account_info_from_cookie?.username
            }
            ],
          }]
        })
      })
    } else {
      fetch(`https://${localIP}/webhook/new-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "@type": "MessageCard",
          "@context": "http://schema.org/extensions",
          "themeColor": "0076D7",
          "summary": passed_summary,
          "sections": [{
            "activityTitle": passed_title,
            "activitySubtitle": passed_subtitle,
            "activityImage": "https://imgs.search.brave.com/8kYrX8kgVCmjBChcrrIJZuhaVFWqzNMNgGKekEhQGx4/rs:fit:560:320:1/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy90/aHVtYi9hL2E3L1Jl/YWN0LWljb24uc3Zn/LzY0MHB4LVJlYWN0/LWljb24uc3ZnLnBu/Zw",
            facts: [{
              name: passed_fact?.key,
              value: passed_fact?.value,
            },
            {
              name: "Location related to error",
              value: passed_link || `https://${localIP}.liberty.edu`,
            },
            {
              name: "User",
              value: account?.username || account_info_from_cookie?.username
            }
            ],
          }]
        })
      })
    }

  }

  function send_intercom_xml_to_rrcs(passed_xml, passed_log, passed_log_type = "INFO", setProgress_indicator_steps) {
    const fetch_url = `https://${localIP}/admin/intercom/view-bypass`
    // fetch(`http://${localIP}:8400/api/intercom/view-bypass`)
    fetch(fetch_url)
      .then(res => res.json())
      .then(data => {
        // alert(data)
        if (data === true) {
          alert("Server is currently bypassed, please un-bypass the server before applying routing")
          return true

        } else {
          // fetch("http://10.239.61.128:8193", {
          fetch(rrcs_localhost_check, {
            method: 'POST',
            headers: {
              'Content-Type': 'text/xml',
            },
            body: passed_xml,
          })
            .then(response => {
              console.log(response)
              if (response.status !== 200) {
                setErrors_while_routing(prev => [...prev, response])
                setLatest_notification({
                  title: `Error ${response?.status}`,
                  subtitle: response?.statusText,
                  kind: "error",
                  current_time: new Date(),
                  color: "red",
                  initializer: "@App.js - send_xml_to_rrcs",
                })
                // setTimeout(() => {
                document.getElementById("lira-notification").style.opacity = 1
                // }, 4000)
                if (setProgress_indicator_steps) {
                  setProgress_indicator_steps(prev => {
                    return {
                      ...prev,
                      failed_routing: true,
                    }
                  })
                }
                send_webhook_to_teams("Error Message from LIRA", "LIRA Message", `Error ${response?.status}: ${response?.statusText} @App.js - send_xml_to_rrcs`, {
                  key: "Fetch URL",
                  value: rrcs_localhost_check
                }, `https://${localIP}.liberty.edu/intercom/kp`, `https://${localIP}.liberty.edu/intercom/bolero`)
              }
              return response.text()
            })
            .then(data => {
              // console.log(data);
              const xml = new XMLParser().parseFromString(data);
              // console.log(xml)
              lockout_count_array.pop()
              if (passed_log) {
                fetch(`https://${localIP}/intercom/new-log`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    id: uuid(),
                    log: passed_log,
                    type: passed_log_type,
                    date: new Date(),
                  }),
                })
              }


              if (lockout_count_array.length === 0 && errors_while_routing.length === 0) {
                // console.log("EMPTY")
                setLockout_user_while_applying(false)
                setLatest_notification({
                  title: "Success",
                  subtitle: "Changes Applied",
                  kind: "success",
                  current_time: new Date(),
                  color: "green",
                  initializer: "",
                })
                // setTimeout(() => {
                document.getElementById("lira-notification").style.opacity = 1
                // }, 4000)
              }
            })
            .catch(error => {
              console.error(error);
              setLatest_notification({
                title: "Error",
                subtitle: "Could not connect to RRCS",
                kind: "error",
                current_time: new Date(),
                color: "red",
                initializer: "@App.js - send_xml_to_rrcs",
              })
              // setTimeout(() => {
              document.getElementById("lira-notification").style.opacity = 1
              // }, 4000)
              send_webhook_to_teams("Error Message from LIRA", "LIRA Message", `Error ${error} @App.js - send_xml_to_rrcs`, {
                "key": "Fetch URL",
                "value": rrcs_localhost_check
              }, `https://${localIP}.liberty.edu/intercom/kp`, `https://${localIP}.liberty.edu/intercom/bolero`)
              setLockout_user_while_applying(false)
              if (setProgress_indicator_steps) {
                setProgress_indicator_steps(prev => {
                  return {
                    ...prev,
                    failed_routing: true,
                  }
                })
              }
            })
          setErrors_while_routing([])
        }
      })
      .catch(error => {
        console.error(error);
        setLatest_notification({
          title: "Error",
          subtitle: "Could not connect to RRCS",
          kind: "error",
          current_time: new Date(),
          color: "red",
          initializer: "@App.js - send_xml_to_rrcs",
        })
        // setTimeout(() => {
        document.getElementById("lira-notification").style.opacity = 1
        // }, 4000)
        send_webhook_to_teams("Error Message from LIRA", "LIRA Message", `Error ${error} - @App.js - send_xml_to_rrcs`, {
          key: "Fetch URL",
          value: fetch_url
        }, `https://${localIP}.liberty.edu/intercom/kp`, `https://${localIP}.liberty.edu/intercom/bolero`)
        setLockout_user_while_applying(false)
        if (setProgress_indicator_steps) {
          setProgress_indicator_steps(prev => {
            return {
              ...prev,
              failed_routing: true,
            }
          })
        }
      })
  }

  // async function parse_conference_xml(rrcs_localhost_check) {
  //   if (full_intercom_conferences.length < 2) {
  //     console.log("fetching conferences")
  //     const returned_conferences_xml = fetchAllConferences(rrcs_localhost_check)
  //     const output = returned_conferences_xml.then(data => {
  //       const xml = new XMLParser().parseFromString(data)
  //       let conferences = xml?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.children[0]?.children
  //       conferences?.forEach(conference => {
  //         const object_id = conference?.children[0]?.children[1]?.children[1]?.children[0]?.value
  //         // console.log("conference", conference)
  //         const conference_name_xml = `<methodCall><methodName>GetObjectProperty</methodName><params><param><value><string>k1234512345</string></value></param><param><value><int>${object_id}</int></value></param><param><value><string>Label</string></value></param></params></methodCall>`
  //         fetch(rrcs_localhost_check, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'text/xml',
  //           },
  //           body: conference_name_xml,
  //         })
  //           .then(response => response.text())
  //           .then(data => {
  //             const xml = new XMLParser().parseFromString(data)
  //             const label = xml?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.value
  //             setFull_intercom_conferences(prev => {
  //               return [...prev,
  //               {
  //                 //^ Currently label is a copy of long name
  //                 label: label || conference?.children[0]?.children[0]?.children[1]?.children[0]?.value,
  //                 long_name: conference?.children[0]?.children[0]?.children[1]?.children[0]?.value,
  //                 id: object_id,
  //                 type: "call-to-conference",
  //               }
  //               ]
  //             })
  //           })
  //       })
  //     })
  //   }
  // }

  // async function parse_ifb_xml(rrcs_localhost_check) {
  //   if (full_intercom_ifbs.length < 1) {
  //     console.log("fetching ifbs")
  //     const returned_ifbs_xml = fetchAllIFBs(rrcs_localhost_check)
  //     returned_ifbs_xml.then(data => {
  //       const xml = new XMLParser().parseFromString(data)
  //       let ifbs = xml?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.children[0]?.children
  //       // console.log(ifbs)
  //       ifbs?.forEach(ifb => {
  //         setFull_intercom_ifbs(prev => {
  //           return [...prev,
  //           {
  //             dim_level: ifb?.children[0]?.children[0]?.children[1]?.children[0]?.value,
  //             label: ifb?.children[0]?.children[3]?.children[1]?.children[0]?.value,
  //             long_name: ifb?.children[0]?.children[4]?.children[1]?.children[0]?.value,
  //             id: ifb?.children[0]?.children[7]?.children[1]?.children[0]?.value,
  //             type: "call-to-ifb",
  //           }
  //           ]
  //         })
  //       })
  //     })
  //   }
  // }

  // async function parse_port_xml(rrcs_localhost_check) {
  //   if (full_intercom_devices.length < 1) {
  //     console.log("fetching devices")
  //     const returned_devices_xml = fetchAllDevices(rrcs_localhost_check)
  //     returned_devices_xml.then(data => {
  //       const xml = new XMLParser().parseFromString(data)
  //       let devices = xml?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.children[0]?.children
  //       // console.log(devices)
  //       devices?.forEach(device => {
  //         //^ Check if this node port pair is a 4-wire or Bolero Wireless Beltpack
  //         if (device?.children[0]?.children[21]?.children[0]?.value === "PortType") {
  //           if (device?.children[0]?.children[21]?.children[1]?.children[0]?.value === "4-Wire") return

  //           else if (device?.children[0]?.children[21]?.children[0]?.value === "PortType") {
  //             if (device?.children[0]?.children[21]?.children[1]?.children[0]?.value === "Bolero Wireless Beltpack") {
  //               setFull_intercom_devices(prev => {
  //                 return [...prev,
  //                 {
  //                   label: device?.children[0]?.children[10]?.children[1]?.children[0]?.value,
  //                   long_name: device?.children[0]?.children[11]?.children[1]?.children[0]?.value,
  //                   id: device?.children[0]?.children[15]?.children[1]?.children[0]?.value,
  //                   net: device?.children[0]?.children[13]?.children[1]?.children[0]?.value,
  //                   node: device?.children[0]?.children[14]?.children[1]?.children[0]?.value,
  //                   page_count: device?.children[0]?.children[17]?.children[1]?.children[0]?.value,
  //                   port: device?.children[0]?.children[18]?.children[1]?.children[0]?.value,
  //                   ip_address: null,
  //                   type: "call-to-port-cmd",
  //                   device_type: device?.children[0]?.children[21]?.children[1]?.children[0]?.value
  //                 }
  //                 ]
  //               })
  //             }
  //           }
  //         }
  //         //^ Check if this node port pair is a 2-wire Output
  //         else if (device?.children[0]?.children[20]?.children[0]?.value === "PortType") {
  //           if (device?.children[0]?.children[20]?.children[1]?.children[0]?.value === "2-Wire Output") return
  //         }
  //         //^ Check if this node port pair is a 2-wire Input
  //         else if (device?.children[0]?.children[16]?.children[0]?.value === "PortType") {
  //           if (device?.children[0]?.children[16]?.children[1]?.children[0]?.value === "2-Wire Input") return
  //         }
  //         //^ Check if this node port pair is a 4-Wire (AES67)
  //         else if (device?.children[0]?.children[22]?.children[0]?.value === "PortType") {
  //           if (device?.children[0]?.children[22]?.children[1]?.children[0]?.value === "4-Wire (AES67)") return
  //         }
  //         //^ Check if this node port pair is RSP-1232HL or RSP-1216HL
  //         else if (device?.children[0]?.children[31]?.children[0]?.value === "PortType") {
  //           if (device?.children[0]?.children[31]?.children[1]?.children[0]?.value === "RSP-1232HL") {
  //             setFull_intercom_devices(prev => {
  //               return [...prev,
  //               {
  //                 label: device?.children[0]?.children[17]?.children[1]?.children[0]?.value,
  //                 long_name: device?.children[0]?.children[18]?.children[1]?.children[0]?.value,
  //                 id: device?.children[0]?.children[24]?.children[1]?.children[0]?.value,
  //                 net: device?.children[0]?.children[22]?.children[1]?.children[0]?.value,
  //                 node: device?.children[0]?.children[23]?.children[1]?.children[0]?.value,
  //                 page_count: device?.children[0]?.children[27]?.children[1]?.children[0]?.value,
  //                 port: device?.children[0]?.children[28]?.children[1]?.children[0]?.value,
  //                 ip_address: device?.children[0]?.children[29]?.children[1]?.children[0]?.children[0]?.children[1]?.children[0]?.value,
  //                 type: "call-to-port-cmd",
  //                 device_type: device?.children[0]?.children[31]?.children[1]?.children[0]?.value,
  //               }
  //               ]
  //             })
  //           }

  //           if (device?.children[0]?.children[31]?.children[1]?.children[0]?.value === "RSP-1216HL") {
  //             setFull_intercom_devices(prev => {
  //               return [...prev,
  //               {
  //                 label: device?.children[0]?.children[17]?.children[1]?.children[0]?.value,
  //                 long_name: device?.children[0]?.children[18]?.children[1]?.children[0]?.value,
  //                 id: device?.children[0]?.children[24]?.children[1]?.children[0]?.value,
  //                 net: device?.children[0]?.children[22]?.children[1]?.children[0]?.value,
  //                 node: device?.children[0]?.children[23]?.children[1]?.children[0]?.value,
  //                 page_count: device?.children[0]?.children[27]?.children[1]?.children[0]?.value,
  //                 port: device?.children[0]?.children[28]?.children[1]?.children[0]?.value,
  //                 ip_address: device?.children[0]?.children[29]?.children[1]?.children[0]?.children[0]?.children[1]?.children[0]?.value,
  //                 type: "call-to-port-cmd",
  //                 device_type: device?.children[0]?.children[31]?.children[1]?.children[0]?.value,
  //               }
  //               ]
  //             })
  //           }
  //         } else {
  //           console.log("Unassigned device found", device?.children[0]?.children)
  //         }
  //       })
  //     })
  //   }
  // }

  function quickCheckForCookie() {
    // return
    const cookieStatus = Cookies.get("lbx-lira");
    if (!cookieStatus) {
      instance.setActiveAccount(null)
      console.log("MAYBE TRIGGER REDIRECT?")
      redirectIfUserIsNotAllowedHere()
    }
  }

  useEffect(() => {
    const cookieStatus = Cookies.get("lbx-lira");
    if (!cookieStatus) {
      instance.setActiveAccount(null)
      console.log("MAYBE TRIGGER LOGIN?")
      setTimeout(() => {
        // if(!user_triggered_logout) {
        handleLoginPopup();
        // }
      }, 0)
    }
  }, []);


  useEffect(() => {
    if (latest_notification?.title === "") return
    console.log("setting clear notification timeout")
    setSalvo_is_routing(false)
    setSalvo_display_modal(false)
    setTimeout(() => {
      document.getElementById("lira-notification").style.opacity = 0
      setTimeout(() => {
        setLatest_notification({
          title: "",
          subtitle: "",
          kind: "",
          current_time: "",
          color: "",
          initializer: "",
        })
      }, 2000)
    }, 8000)
  }, [latest_notification])

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      const element = e.target

      if (appSwitcherVisible) {
        setAppSwitcherVisible(false)
      } else {
        if (element?.closest("button")?.id === "side_panel_hamburger" && !element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setSide_panel_visible(true)
          return
        } else if (element?.closest("button")?.id === "side_panel_hamburger" && element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setSide_panel_visible(false)
          return
        }
        if (element?.closest("button")?.id === "navBarNotificationButton" && !element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(true)
          return
        } else if (element?.closest("button")?.id === "navBarNotificationButton" && element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(false)
          return
        }
        if (element?.closest("button")?.id === "navBarUserAvatarButton" && !element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(true)
          return
        } else if (element?.closest("button")?.id === "navBarUserAvatarButton" && element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(false)
          return
        }
        if (element?.closest("button")?.id === "navBarSwitcherButton" && !element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(true)
          return
        } else if (element?.closest("button")?.id === "navBarSwitcherButton" && element?.closest("button")?.classList.contains("cds--header__action--active")) {
          setAppSwitcherVisible(false)
          return
        }

        // if (element?.closest("button")?.id === "navBarSwitcherButton" || element?.closest("button")?.classList.contains("cds--switcher")) {
        //   // console.log("opened switcher")
        //   setAppSwitcherVisible(true)
        //   setSelectedGlobalAction("switcher")
        //   return
        // } else if (element?.closest("button")?.id === "navBarUserAvatarButton" || element?.closest("button")?.classList.contains("cds--switcher")) {
        //   // console.log(element.closest("button"))
        //   setAppSwitcherVisible(true)
        //   setSelectedGlobalAction("user")
        //   return
        // } 
        // else if (element?.closest("button")?.id === "navBarNotificationButton") {
        //   // console.log(element.closest("button"))
        //   setAppSwitcherVisible(false)
        //   setSelectedGlobalAction("notification")
        //   // return
        // } 
        // else if (element?.closest("button")?.id === "navBarSettingsButton") {
        //   // console.log(element.closest("button"))
        //   setAppSwitcherVisible(false)
        //   setSelectedGlobalAction("settings")
        //   // return
        // }

        else {
          // console.log("ELSE")
          setSide_panel_visible(false)
          setAppSwitcherVisible(false)
          setSelectedGlobalAction("")
          // console.log("closed switcher")
        }
      }
    })
  }, [])

  useEffect(() => {
    console.log("Switcher State", appSwitcherVisible)
  }, [appSwitcherVisible])

  // useEffect(() => {
  //   console.log(full_intercom_devices)
  // },[full_intercom_devices])

  useEffect(() => {
    console.log("Pages", whatPagesCanThisUserAccess(serverPermissions))
  }, [serverPermissions])

  useEffect(( ) => {
    console.log("Lira Tiers", liraTiersFromCookie)
    if(loggedInUserPageAccess?.home?.toLowerCase() === "none" && liraTiersFromCookie?.length === 0) return
    if(liraTiersFromCookie?.length === 0) {
      console.log("NO LIRA TIER IN COOKIES")
      setLoggedInUserPageAccess(DEfAULT_USER_ACCESS)
    }
  }, [liraTiersFromCookie, DEfAULT_USER_ACCESS])

  return (
    <div id="lira-container" className='overflow-hidden bg-black'>
      <div id="lira-notification" ref={notification_element} className='transition-opacity duration-0' style={{ opacity: 0 }}>
        <ToastNotification
          onClick={() => {
            document.getElementById("lira-notification").style.opacity = 0
          }}
          onCloseButtonClick={() => {
            setLatest_notification({
              title: "",
              subtitle: "",
              kind: "",
              current_time: "",
              color: "",
              initializer: ""
            })
          }} style={{ position: "fixed", right: "10px", top: "3rem", zIndex: 999, border: `1px solid ${latest_notification?.color}` }} kind={latest_notification?.kind} role="status" timeout={0} title={latest_notification?.title} subtitle={latest_notification?.subtitle} caption={latest_notification?.initializer}
        />
      </div>

      <Theme theme='g100'>
        <NavBar
          productionBuildVersion={productionBuildVersion}
          active_account={account}
          cookie_has_been_assigned={cookie_has_been_assigned}
          global_account_access_tier={global_account_access_tier}
          account_info_from_cookie={account_info_from_cookie}
          side_panel_visible={side_panel_visible}
          setSide_panel_visible={setSide_panel_visible}
          appSwitcherVisible={appSwitcherVisible}
          setAppSwitcherVisible={setAppSwitcherVisible}
          selectedGlobalAction={selectedGlobalAction}
          setSelectedGlobalAction={setSelectedGlobalAction}
          intercom_routing_type={intercom_routing_type}
          setIntercom_routing_type={setIntercom_routing_type}
          intercomStatusSubLink={intercomStatusSubLink}
          setIntercomStatusSubLink={setIntercomStatusSubLink}
          settings_routing_type={settings_routing_type}
          setSettings_routing_type={setSettings_routing_type}
          advancedRoutesSubLink={advancedRoutesSubLink}
          setAdvancedRoutesSubLink={setAdvancedRoutesSubLink}
          scheduleSubLink={scheduleSubLink}
          setScheduleSubLink={setScheduleSubLink}
          captioningSubLink={captioningSubLink}
          setCaptioningSubLink={setCaptioningSubLink}
          polatisSubLink={polatisSubLink}
          setPolatisSubLink={setPolatisSubLink}
          ccuOrRcpSubLink={ccuOrRcpSubLink}
          setCcuOrRcpSubLink={setCcuOrRcpSubLink}
          login_popup_func={handleLoginPopup}
          logout_func={handleLogoutPopup}
          serverPermissions={serverPermissions}
          loggedInUserPageAccess={loggedInUserPageAccess}
          setSettingsModalInfo={setSettingsModalInfo}
        />

        {/* {settingsModalInfo?.open && <AdminPage
          localIP={localIP}
          admin_controls={admin_controls}
          setAdmin_controls={setAdmin_controls}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          setServerPermissions={setServerPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          settings_routing_type={settings_routing_type}
          setSettings_routing_type={setSettings_routing_type}
          setLatest_notification={setLatest_notification}
          send_webhook_to_teams_func={send_webhook_to_teams}
          loggedInUserPageAccess={loggedInUserPageAccess}
          handleLoginPopup={handleLoginPopup}
          settingsModalInfo={settingsModalInfo}
          setSettingsModalInfo={setSettingsModalInfo}
        />} */}
      </Theme>

      <Routes>

        <Route path='/' element={["full", "view"]?.includes(loggedInUserPageAccess?.home?.toLowerCase()) ? 
        // <BasicHome
        //   localIP={localIP}
        //   loggedInUserPageAccess={loggedInUserPageAccess}
        //   authorizationCheck={authorizationCheck}
        //   whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
        //   serverPermissions={serverPermissions}
        //   global_account_access_tier={global_account_access_tier}
        //   handleLoginPopup={handleLoginPopup}
        //   account_info_from_cookie={account_info_from_cookie}
        //   active_account={account}
        //   redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
        //   liraTiersFromCookie={liraTiersFromCookie}
        // /> 
        <FullScreenSchedule
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
          scheduleSubLink={scheduleSubLink}
          liraTiersFromCookie={liraTiersFromCookie}
        />
        : 
        <WelcomePage 
          handleLoginPopup={handleLoginPopup}
        />
        // <FullScreenSchedule
        //   localIP={localIP}
        //   authorizationCheck={authorizationCheck}
        //   whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
        //   global_account_access_tier={global_account_access_tier}
        //   redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
        //   quickCheckForCookie={quickCheckForCookie}
        //   serverPermissions={serverPermissions}
        //   account_info_from_cookie={account_info_from_cookie}
        //   active_account={account}
        //   loggedInUserPageAccess={loggedInUserPageAccess}
        //   scheduleSubLink={scheduleSubLink}
        //   liraTiersFromCookie={liraTiersFromCookie}
        // />
        } />

        {/* <Route path='/advanced-routes' element={["full", "view"]?.includes(loggedInUserPageAccess?.magnum?.toLowerCase()) ? <AdvancedRouting
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          advancedRoutesSubLink={advancedRoutesSubLink}
          setLatest_notification={setLatest_notification}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/captioning' element={["full", "view"]?.includes(loggedInUserPageAccess?.captioning?.toLowerCase()) ? <Captioning
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          captioningSubLink={captioningSubLink}
          setCaptioningSubLink={setCaptioningSubLink}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/ccu' element={["full", "view"]?.includes(loggedInUserPageAccess?.ccu?.toLowerCase()) ? <CCUorRCPPage
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          ccuOrRcpSubLink={ccuOrRcpSubLink}
          setCCUOrRCPSubLink={setCcuOrRcpSubLink}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/dante' element={["full", "view"]?.includes(loggedInUserPageAccess?.dante?.toLowerCase()) ? <DantePage
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          setLatest_notification={setLatest_notification}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/engineering' element={["full", "view"]?.includes(loggedInUserPageAccess?.engineering?.toLowerCase()) ? <EngHome
          localIP={localIP}
          loggedInUserPageAccess={loggedInUserPageAccess}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          whatGroupsDoesThisUserHave={whatGroupsDoesThisUserHave}
          liraTiersFromCookie={liraTiersFromCookie}
          send_xml_to_rrcs_func={send_intercom_xml_to_rrcs}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/magnum' element={["full", "view"]?.includes(loggedInUserPageAccess?.magnum?.toLowerCase()) ? <MobileMagnum
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/intercom' element={["full", "view"]?.includes(loggedInUserPageAccess?.intercom?.toLowerCase()) ? <IntercomPage
          localIP={localIP}
          send_xml_to_rrcs_func={send_intercom_xml_to_rrcs}
          setLatest_notification={setLatest_notification}
          lockout_count_array={lockout_count_array}
          lockout_user_while_applying={lockout_user_while_applying}
          setLockout_user_while_applying={setLockout_user_while_applying}
          intercom_navigation_tabs={intercom_navigation_tabs}
          active_navigation_tab={active_navigation_tab}
          setActive_navigation_tab={setActive_navigation_tab}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          intercomStatusSubLink={intercomStatusSubLink}
          setIntercomStatusSubLink={setIntercomStatusSubLink}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/intercom/kp/:id?' element={["full", "view"]?.includes(loggedInUserPageAccess?.intercom?.toLowerCase()) ? <IntercomKeyPanelPage
          localIP={localIP}
          send_xml_to_rrcs_func={send_intercom_xml_to_rrcs}
          setLatest_notification={setLatest_notification}
          lockout_count_array={lockout_count_array}
          lockout_user_while_applying={lockout_user_while_applying}
          setLockout_user_while_applying={setLockout_user_while_applying}
          intercom_routing_type={intercom_routing_type}
          setIntercom_routing_type={setIntercom_routing_type}
          intercom_navigation_tabs={intercom_navigation_tabs}
          active_navigation_tab={active_navigation_tab}
          setActive_navigation_tab={setActive_navigation_tab}
          full_intercom_conferences={full_intercom_conferences}
          setFull_intercom_conferences={setFull_intercom_conferences}
          full_intercom_ifbs={full_intercom_ifbs}
          setFull_intercom_ifbs={setFull_intercom_ifbs}
          full_intercom_devices={full_intercom_devices}
          setFull_intercom_devices={setFull_intercom_devices}
          blacklisted_conferences={blacklisted_conferences}
          setBlacklisted_conferences={setBlacklisted_conferences}
          parse_conference_xml_func={parse_conference_xml}
          parse_ifb_xml_func={parse_ifb_xml}
          parse_port_xml_func={parse_port_xml}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/intercom/bolero/:id?' element={["full", "view"]?.includes(loggedInUserPageAccess?.intercom?.toLowerCase()) ? <IntercomBoleroPage
          localIP={localIP}
          send_xml_to_rrcs_func={send_intercom_xml_to_rrcs}
          setLatest_notification={setLatest_notification}
          lockout_count_array={lockout_count_array}
          lockout_user_while_applying={lockout_user_while_applying}
          setLockout_user_while_applying={setLockout_user_while_applying}
          intercom_routing_type={intercom_routing_type}
          setIntercom_routing_type={setIntercom_routing_type}
          intercom_navigation_tabs={intercom_navigation_tabs}
          active_navigation_tab={active_navigation_tab}
          setActive_navigation_tab={setActive_navigation_tab}
          full_intercom_conferences={full_intercom_conferences}
          setFull_intercom_conferences={setFull_intercom_conferences}
          full_intercom_ifbs={full_intercom_ifbs}
          setFull_intercom_ifbs={setFull_intercom_ifbs}
          full_intercom_devices={full_intercom_devices}
          setFull_intercom_devices={setFull_intercom_devices}
          blacklisted_conferences={blacklisted_conferences}
          setBlacklisted_conferences={setBlacklisted_conferences}
          parse_conference_xml_func={parse_conference_xml}
          parse_ifb_xml_func={parse_ifb_xml}
          parse_port_xml_func={parse_port_xml}
          salvo_is_routing={salvo_is_routing}
          setSalvo_is_routing={setSalvo_is_routing}
          salvo_display_modal={salvo_display_modal}
          setSalvo_display_modal={setSalvo_display_modal}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/monitoring' element={["full", "view"]?.includes(loggedInUserPageAccess?.monitoring?.toLowerCase()) ? <Monitoring
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/nmos' element={["full", "view"]?.includes(loggedInUserPageAccess?.nmos?.toLowerCase()) ? <NMOSRouting
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/omni' element={["full", "view"]?.includes(loggedInUserPageAccess?.omni?.toLowerCase()) ? <OmniRouting
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          send_xml_to_rrcs_func={send_intercom_xml_to_rrcs}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/polatis' element={["full", "view"]?.includes(loggedInUserPageAccess?.polatis?.toLowerCase()) ? <Polatis
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          polatisSubLink={polatisSubLink}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/radio' element={["full", "view"]?.includes(loggedInUserPageAccess?.radio?.toLowerCase()) ? <RadioStreamsPage
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
          liraTiersFromCookie={liraTiersFromCookie}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/roku/1' element={["full", "view"]?.includes(loggedInUserPageAccess?.roku?.toLowerCase()) ? <RokuControlOne
          localIP={localIP}
          staticRemote="Roku 1"
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/roku/2' element={["full", "view"]?.includes(loggedInUserPageAccess?.roku?.toLowerCase()) ? <RokuControlTwo
          localIP={localIP}
          staticRemote="Roku 2"
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/salvos' element={["full", "view"]?.includes(loggedInUserPageAccess?.salvos?.toLowerCase()) ? <Salvos
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          polatisSubLink={polatisSubLink}
          send_intercom_xml_to_rrcs={send_intercom_xml_to_rrcs}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/settings/:project' element={["full", "view"]?.includes(loggedInUserPageAccess?.admin?.toLowerCase()) ? <AdminControlProjectButtons
          localIP={localIP} admin_controls={admin_controls}
          setAdmin_controls={setAdmin_controls}
          setLatest_notification={setLatest_notification}
          send_webhook_to_teams_func={send_webhook_to_teams}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        {/* <Route path='/settings/intercom/conference-blacklist' element={["full", "view"]?.includes(loggedInUserPageAccess?.admin?.toLowerCase()) ? <IntercomConferenceBlacklistEditor
          localIP={localIP}
          blacklisted_conferences={blacklisted_conferences}
          setBlacklisted_conferences={setBlacklisted_conferences}
          rrcs_localhost_check={rrcs_localhost_check}
          parse_conference_xml_func={parse_conference_xml}
          full_intercom_conferences={full_intercom_conferences}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          loggedInUserPageAccess={loggedInUserPageAccess}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} /> */}

        <Route path='/lockdown' element={<UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} />
        {/* <Route path='/test' element={<WebSocketComponent/>} /> */}

        <Route path='/schedule' element={["full", "view"]?.includes(loggedInUserPageAccess?.schedule?.toLowerCase()) ? <FullScreenSchedule
          localIP={localIP}
          authorizationCheck={authorizationCheck}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          global_account_access_tier={global_account_access_tier}
          redirectIfUserIsNotAllowedHere={redirectIfUserIsNotAllowedHere}
          quickCheckForCookie={quickCheckForCookie}
          serverPermissions={serverPermissions}
          account_info_from_cookie={account_info_from_cookie}
          active_account={account}
          loggedInUserPageAccess={loggedInUserPageAccess}
          scheduleSubLink={scheduleSubLink}
          liraTiersFromCookie={liraTiersFromCookie}
        /> : <UnableToAccessPage 
          handleLoginPopup={handleLoginPopup}
          whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
          serverPermissions={serverPermissions}  
        />} />

      </Routes>
    </div>
  )
}