// export async function fetchUserPreferences(localIP, usernameForServer) {
export async function fetchUserPreferences(_, usernameForServer) {
    const localIP = "lbx-lira.liberty.edu"
    console.log("Fetching user preferences for: ", usernameForServer)
    const res = await fetch(`https://${localIP}/user-preferences/${usernameForServer}`)
    if(!res.ok) return {
        error: res.status
    }
    const data = await res.json()
    return data?.[usernameForServer]
}

export async function postUserPreferences(localIP, usernameForServer, preferences) {
    if(!localIP || !usernameForServer || !preferences) {
        console.warn("Missing params", localIP, usernameForServer, preferences)
        return
    }
    try {
        const res = await fetch(`https://${localIP}/user-preferences/${usernameForServer}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(preferences)
        })
        if(!res.ok) return {
            error: res.status
        }
        const data = await res.json()
        return data
    } catch (error) {
        console.log(error)
    }
}