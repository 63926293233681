import { Column, Grid, Theme, Button, Modal, Table, TableToolbarMenu, TableToolbarContent, TableToolbarAction, InlineNotification, TableBody, TableHead, TableHeader, TableRow, TableCell, TableToolbar, Tooltip, TableToolbarSearch, Tile, TextInput, Select, DatePicker, DatePickerInput, SelectItem, RadioButton, RadioButtonGroup, Slider, Checkbox, OverflowMenu, OverflowMenuItem, FormLabel, ToastNotification, Popover, PopoverContent } from "@carbon/react"
import React, { useCallback, useEffect, useState, Fragment, useRef, forwardRef, useMemo, useLayoutEffect } from "react";
import ReactDOM from 'react-dom';
import { debounce } from 'lodash';
import { FaCirclePlus } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { BiColumns } from "react-icons/bi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { FaTrashAlt, FaPen } from "react-icons/fa";
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx";
import { MdKeyboardArrowDown, MdInvertColors, MdInvertColorsOff } from "react-icons/md";
import { IoDuplicate } from "react-icons/io5";
import { IoMdOptions } from "react-icons/io";
import { RiDraggable } from "react-icons/ri";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import { v4 as uuid } from 'uuid';
import "./Schedule.css"
import { fetchUserPreferences, postUserPreferences } from "../hooksAndHelpers/fetchUserPreferences";
import ScheduleSettings from "./ScheduleSettings";
// import QCFeedWithAudioMeters from "../components/QCFeedWithAudioMeters";
import Draggable from 'react-draggable';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {CSS} from '@dnd-kit/utilities';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import ScheduleTicketGenerationFields from "./ScheduleTicketGenerationFields";
import ScheduleConfigureFavorites from "./ScheduleConfigureFavorites";

const COLUMN_VALUES_CASE_SENSITIVE = {
    "Day": {
        lower: "day",
        upper: "Day",
    },
    "Date": {
        lower: "date",
        upper: "Date",
    },
    "Start Time": {
        lower: "start time",
        upper: "Start Time",
    },
    "Event": {
        lower: "event",
        upper: "Event",
    },
    "Name": {
        lower: "name",
        upper: "Name",
    },
    "Location": {
        lower: "location",
        upper: "Location",
    },
    "PCR": {
        lower: "pcr",
        upper: "PCR",
    },
    "Platform": {
        lower: "platform",
        upper: "Platform",
    },
    "Platform URL": {
        lower: "platform url",
        upper: "Platform URL",
    },
    "Encoder": {
        lower: "encoder",
        upper: "Encoder",
    },
    "Ticket": {
        lower: "ticket",
        upper: "Ticket",
    },
    "LBXC": {
        lower: "lbxc",
        upper: "LBXC",
    },
    "Producer": {
        lower: "producer",
        upper: "Producer",
    },
    "EIC": {
        lower: "eic",
        upper: "EIC",
    },
    "Field Engineer": {
        lower: "field engineer",
        upper: "Field Engineer",
    },
    "Tech Manager": {
        lower: "tech manager",
        upper: "Tech Manager",
    },
    "Coverage": {
        lower: "coverage",
        upper: "Coverage",
    },
    "ORG": {
        lower: "org",
        upper: "ORG",
    },
    "Client": {
        lower: "client",
        upper: "Client",
    },
    "Type": {
        lower: "type",
        upper: "Type",
    },
    "Notes": {
        lower: "notes",
        upper: "Notes",
    },
}

const NEW_EVENT_RECURRENCE_RULES = {
    "Event Does Not Repeat": "Event Does Not Repeat",
    "Daily": "Daily",
    "Weekly": "Weekly",
}
const NEW_EVENT_END_RECURRENCE_RULES = {
    "Date": "Date",
    "After": "After",
}

const DEFAULT_NEW_EVENT_KEY_VALUES = {
    open: false,
    edit: false,
    duplicate: false,
    createDisabled: true,
    [COLUMN_VALUES_CASE_SENSITIVE["Event"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Platform"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["PCR"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Name"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Date"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Start Time"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Location"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Client"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Ticket"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Coverage"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Producer"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Field Engineer"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Tech Manager"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["EIC"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["LBXC"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Encoder"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["ORG"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Type"].lower]: "TBD",
    [COLUMN_VALUES_CASE_SENSITIVE["Notes"].lower]: "",
    [COLUMN_VALUES_CASE_SENSITIVE["Platform URL"].lower]: "",
    "ticket link": "",
    recurrenceRule: NEW_EVENT_RECURRENCE_RULES["Event Does Not Repeat"],
    endRecurrenceRuleType: NEW_EVENT_END_RECURRENCE_RULES["After"],
    endRecurrenceRuleValue: 1,
    hide: false,
}

const NUMBER_THAT_ADDS_SPACE_FOR_SORTING_ARROW_ICON_TO_FIT_WITH_ALL_COLUMNS = 4

export default function Schedule({
    localIP,
    authorizationCheck,
    whatPagesCanThisUserAccess,
    global_account_access_tier,
    redirectIfUserIsNotAllowedHere,
    quickCheckForCookie,
    serverPermissions,
    account_info_from_cookie,
    active_account,
    loggedInUserPageAccess,
    liraTiersFromCookie,
    isRightClickMenuOpen,
    setIsRightClickMenuOpen,
}) {
    const [currentHoveredPane, setCurrentHoveredPane] = useState(null);
    const [scrollToFirstEntry, setScrollToFirstEntry] = useState(true);

    //* --------------------------------------
    //* User Preferences
    //* --------------------------------------
    const [isStartTime12HRFormat, setIsStartTime12HRFormat] = useState(true);

    const [scheduleEntries, setScheduleEntries] = useState([]);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [isTodaysEventsFullScreen, setIsTodaysEventsFullScreen] = useState(false);
    const [isQCFeedFullScreen, setIsQCFeedFullScreen] = useState(false);
    const [isMainScheduleFullScreen, setIsMainScheduleFullScreen] = useState(false);
    const [disableActionsBasedOffUserRole, setDisableActionsBasedOffUserRole] = useState(false)
    const [isInEditMode, setIsInEditMode] = useState(true)
    const [userPreferences, setUserPreferences] = useState(null)
    const [todaysEventsSelectedEvent, setTodaysEventsSelectedEvent] = useState(null)
    const lastLoadedRef = useRef(null)
    const [visibleRows, setVisibleRows] = useState(0);
    const [escapeFullScreenWillAlsoClearSelectedEntry, setEscapeFullScreenWillAlsoClearSelectedEntry] = useState(false);
    const [estimateEventLengths, setEstimateEventLengths] = useState([]);
    const [currentActivePlaylistFeed, setCurrentActivePlaylistFeed] = useState([]);
    const [fullPlaylistFeed, setFullPlaylistFeed] = useState([]);


    function formatDate(dateString) {
        //^ mm/dd/yyyy
        const date = new Date(dateString);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }

    const customCompare = (valueA, valueB, sortByKey) => {
        // Handle null or undefined values
        if (valueA == null) return -1;
        if (valueB == null) return 1;

        // Convert to numbers if possible
        const numA = parseFloat(valueA);
        const numB = parseFloat(valueB);
        const isNumeric = !isNaN(numA) && !isNaN(numB);

        // Parse dates if applicable
        const parseDate = (dateStr) => {
            // Implement date parsing logic as per your date format
            // Example:
            return new Date(dateStr);
        };

        // Compare based on data types
        if (sortByKey === 'date') {
            const dateA = parseDate(valueA);
            const dateB = parseDate(valueB);
            if (dateA && dateB) return dateA - dateB;
        } else if (isNumeric) {
            return numA - numB;
        }

        // Default to string comparison
        return valueA.localeCompare(valueB);
    };

    async function fetchLBXSchedule() {
        const res = await fetch(`https://${localIP}/schedule/view-full`)
        if(!res.ok) {
            alert("Cannot fetch /schedule/view-full - Status Code: " + res.status)
            return
        }

        const data = await res.json()
        console.log("Data", data);
        // setScheduleEntries([...data]?.slice(data?.length -3))
        setScheduleEntries([...data])
    }

    useEffect(() => {
        document.title = `LBX${localIP?.toLowerCase()?.includes("dev") ? " DEV" : ""} Schedule`

        fetchLBXSchedule();

        return () => {
            // setScheduleSearch([""]);
            setScheduleEntries([]);
            // setFilteredScheduleEntries([]);
            // setLastInstanceOfModalEvent({});
            setHasInitialized(false);
            // setColorRules({});
            setVisibleRows(0);
            setScrollToFirstEntry(true);
        }
    }, [])

    useEffect(() => {
        whatPagesCanThisUserAccess(serverPermissions)
        console.log("LOGGED IN USER ACCESS ACCORDING TO SCHEDULE PAGE", loggedInUserPageAccess)
    }, [serverPermissions])

    useEffect(() => {
        if(!account_info_from_cookie?.username) return
        // console.log("account_info_from_cookie", account_info_from_cookie, account_info_from_cookie?.username, account_info_from_cookie?.username?.split("@")[0])
        fetchUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0])
            .then((data) => {
                setUserPreferences(data)
                // console.log("user preferences", data)
            })
    }, [account_info_from_cookie, localIP])

    useEffect(() => {
        if(userPreferences?.hasOwnProperty("schedule-is-start-time-12-hr-format")) {
            setIsStartTime12HRFormat(userPreferences["schedule-is-start-time-12-hr-format"])
        }
    }, [userPreferences])

    useEffect(() => {
        if(isMainScheduleFullScreen) {
            try {
                document.getElementById("lira-schedule-top-half-container").style.display = "grid"
                document.getElementById("lira-schedule-main-container").style.height = "calc(53vh - 3rem)"
            } catch (error) {
                console.log(error)
            }
        }
        if(scheduleEntries?.length < 1) return
        if (!document.getElementById("lira-schedule-top-half-container")) {
            setIsMainScheduleFullScreen(false)
            return
        }

        try {
            if (isMainScheduleFullScreen) {
                try {
                    document.getElementById("lira-schedule-top-half-container").style.display = "none"
                    document.getElementById("lira-schedule-main-container").style.height = "calc(100vh - 3rem)"
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    document.getElementById("lira-schedule-top-half-container").style.display = "grid"
                    document.getElementById("lira-schedule-main-container").style.height = "calc(53vh - 3rem)"
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error)
        }
        
    }, [isMainScheduleFullScreen, scheduleEntries])
    
    useEffect(() => {
        try {
            if (isTodaysEventsFullScreen) {
                try {
                    document.getElementById("lira-schedule-main-container").parentElement.style.display = "none"
                    document.getElementById("lira-schedule-qc-feed-container").style.display = "none"
                    document.getElementById("lira-schedule-todays-events-container").style.zIndex = "9999"
                    document.getElementById("lira-schedule-todays-events-container").style.position = "absolute"
                    document.getElementById("lira-schedule-todays-events-container").style.height = "calc(100vh - 3rem)"
                    document.getElementById("lira-schedule-todays-events-container").style.width = "100vw"
                    
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.zIndex = "9999"
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.position = "absolute"
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.height = "calc(100vh - 3rem)"
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.width = "100vw"
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    document.getElementById("lira-schedule-main-container").parentElement.style.display = ""
                    document.getElementById("lira-schedule-qc-feed-container").style.display = ""
                    document.getElementById("lira-schedule-todays-events-container").style.zIndex = ""
                    document.getElementById("lira-schedule-todays-events-container").style.position = "relative"
                    document.getElementById("lira-schedule-todays-events-container").style.height = ""
                    document.getElementById("lira-schedule-todays-events-container").style.width = ""
                    
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.position = "relative"
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.height = ""
                    document.getElementById("lira-schedule-todays-events-container").querySelector(".cds--data-table-content").style.width = ""
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error)
        }
        
    }, [isTodaysEventsFullScreen])
    
    useEffect(() => {
        try {
            if (isQCFeedFullScreen) {
                try {
                    document.getElementById("lira-schedule-main-container").parentElement.style.display = "none"
                    document.getElementById("lira-schedule-todays-events-container").style.display = "none"
                    document.getElementById("lira-schedule-qc-feed-container").style.zIndex = "9999"
                    document.getElementById("lira-schedule-qc-feed-container").style.position = "absolute"
                    document.getElementById("lira-schedule-qc-feed-container").style.height = "calc(100vh - 3rem)"
                    document.getElementById("lira-schedule-qc-feed-container").style.width = "100vw"
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    document.getElementById("lira-schedule-main-container").parentElement.style.display = ""
                    document.getElementById("lira-schedule-todays-events-container").style.display = ""
                    document.getElementById("lira-schedule-qc-feed-container").style.zIndex = ""
                    document.getElementById("lira-schedule-qc-feed-container").style.position = "relative"
                    document.getElementById("lira-schedule-qc-feed-container").style.height = ""
                    document.getElementById("lira-schedule-qc-feed-container").style.width = ""
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error)
        }
        
    }, [isQCFeedFullScreen])

    useEffect(() => {
        console.log("SCHEDULE PAGE CHECK",loggedInUserPageAccess)
        if (loggedInUserPageAccess?.schedule?.toLowerCase() !== "full") {
            setDisableActionsBasedOffUserRole(true)
        } else if (loggedInUserPageAccess?.schedule?.toLowerCase() === "full") {
            setDisableActionsBasedOffUserRole(false)
        }
    }, [loggedInUserPageAccess])

    const handleTildaKeyDown = useCallback((event) => {
        // console.log("Key", event.key);
        // console.log("Current Hovered Pane", currentHoveredPane);
        if(event.key === "`" || (event.key === "Escape" && (isMainScheduleFullScreen || isQCFeedFullScreen || isTodaysEventsFullScreen))) {
            if(event.key === "Escape" && escapeFullScreenWillAlsoClearSelectedEntry) {
                setEscapeFullScreenWillAlsoClearSelectedEntry(false);
                return
            }
            if(isMainScheduleFullScreen || isQCFeedFullScreen || isTodaysEventsFullScreen) {
                setIsMainScheduleFullScreen(false);
                setIsQCFeedFullScreen(false);
                setIsTodaysEventsFullScreen(false);
                return
            }
            switch(currentHoveredPane) {
                case "lira-schedule-todays-events-container":
                    setIsQCFeedFullScreen(false);
                    setIsMainScheduleFullScreen(false);
                    setIsTodaysEventsFullScreen(!isTodaysEventsFullScreen);
                    break;
                case "lira-qc-feed-container":
                    setIsTodaysEventsFullScreen(false);
                    setIsMainScheduleFullScreen(false);
                    setIsQCFeedFullScreen(!isQCFeedFullScreen);
                    break;
                case "lira-schedule-main-schedule-container":
                    setIsTodaysEventsFullScreen(false);
                    setIsQCFeedFullScreen(false);
                    setIsMainScheduleFullScreen(!isMainScheduleFullScreen);
                    break;
                default:
                    break;
            }
        }
    }, [currentHoveredPane, isTodaysEventsFullScreen, isQCFeedFullScreen, isMainScheduleFullScreen, escapeFullScreenWillAlsoClearSelectedEntry]);

    //^ Use useEffect to add event listener when component mounts
    useEffect(() => {
        document.addEventListener('keydown', handleTildaKeyDown);

        // document.addEventListener('keydown', handleTildaKeyDown);

        //^ Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleTildaKeyDown);

            // document.removeEventListener('keydown', handleTildaKeyDown);
        };
    }, [handleTildaKeyDown]);
    
    useEffect(() => {

        //^ Cleanup function to remove event listener when component unmounts
        return () => {
        };
    }, [handleTildaKeyDown]);

    //^ Auto focus date picker input 
    useEffect(() => {
        const checkForDatePickerInput = () => {
          const cellInEditing = document.querySelector('.cell-in-editing');
          const datePickerInput = cellInEditing && cellInEditing.querySelector('.cds--date-picker__input');
          if (datePickerInput) {
            datePickerInput.focus();
          }
        };
    
        const observer = new MutationObserver(checkForDatePickerInput);
        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
    
        return () => {
          observer.disconnect();
        };
      }, []);

    useEffect(() => {
        if(!todaysEventsSelectedEvent) return
        // console.log("todaysEventsSelectedEvent", todaysEventsSelectedEvent)
        document?.getElementById(todaysEventsSelectedEvent?.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setTimeout(() => {
            setTodaysEventsSelectedEvent(null)
        }, 2000)
    }, [todaysEventsSelectedEvent])

    // useEffect(() => {
    //     console.log("modalEvent", modalEvent)
    // }, [modalEvent])

    // useEffect(() => {
    //     console.log("currentHoveredPane", currentHoveredPane)
    // }, [currentHoveredPane])

    const todaysDate = new Date();
    // Options for formatting the day, month, and date
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const formattedDate = todaysDate.toLocaleDateString('en-US', options);

    // Since toLocaleDateString doesn't directly give us the format we want, we need to modify the result
    const [weekday, monthDay] = formattedDate.split(', ');

    // Format the final string to "Tuesday, July 16"
    const customFormattedTodaysDate = `${weekday}, ${monthDay}`;

    return (
        <Theme id="lira-schedule-page" theme="g90" style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "3rem",
            height: "calc(100vh - 3rem)",
          }}>
            <>
                {/* <div className="absolute grid gap-2 bottom-4 left-4 bg-[#3d3d3d] py-[5px] px-[3px]" */}
                <div id="lira-schedule-top-half-container" className="grid grid-cols-[1fr,1fr] min-h-[calc(53vh-3rem)] bg-black"
                    onClick={() => {
                        setIsRightClickMenuOpen({
                            isOpen: false,
                            whichCell: null
                        })
                    }}
                >
                    <div id="lira-schedule-todays-events-container" className="relative"
                        // style={{ border: currentHoveredPane === todaysEventsRef ? "1px solid rgb(255, 255, 255)" : "none" }}
                        onMouseOver={() => setCurrentHoveredPane("lira-schedule-todays-events-container")}
                    >
                        <div className="absolute grid gap-2 bottom-4 left-4 bg-[rgb(0,0,0,0.4)] z-[1000] py-[5px] px-[3px]"
                            style={{ gridTemplateColumns: "1fr", borderRadius: "5px", boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.9)" }}
                        >
                            {isTodaysEventsFullScreen ? <RxExitFullScreen className="exit-full-screen-icon cursor-pointer w-[35px] h-[35px]"
                                    onClick={() => {
                                        setIsTodaysEventsFullScreen(false)
                                    }}
                                /> : <RxEnterFullScreen className="enter-full-screen-icon cursor-pointer w-[35px] h-[35px]"
                                    onClick={() => {
                                        setIsTodaysEventsFullScreen(true)
                                    }}
                                />}
                        </div>
                        {scheduleEntries?.filter(entry => entry?.date === formatDate(new Date()) && entry?.coverage?.toLowerCase() === "yes")?.length < 1 ? <div className="flex items-center justify-center h-[calc(53vh-3rem)] bg-[#484848]"><h2>No Events Today</h2></div>
                            : <Tile style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <h2 className="mb-2">Today's Events <span id="lira-schedule-todays-events-mobile">- {customFormattedTodaysDate}</span></h2>
                                <div className="flex flex-col h-full"
                                    style={{
                                        overflowY: "scroll",
                                        scrollbarWidth: "none",
                                        "&::-webkit-scrollbar": {
                                            width: "0px",
                                            background: "transparent",
                                        },
                                    }}
                                >
                                    {scheduleEntries?.filter(entry => entry?.date === formatDate(new Date()) && entry?.coverage?.toLowerCase() === "yes")?.sort((a, b) => customCompare(a?.["start time"], b?.["start time"], "start time"))?.map((event, index) => {
                                        const eventsTimeRemainingBarPercent = estimateEventLengths?.find(checkAgainst => checkAgainst?.[0]?.id === event?.id)?.[1]
                                        const eventInFullPlaylistFeed = fullPlaylistFeed?.find(checkAgainst => checkAgainst?.id === event?.id)
                                        // console.log("eventInFullPlaylistFeed", eventInFullPlaylistFeed)
                                        const formattedTime = new Date(event?.date + " " + event?.["start time"])?.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: isStartTime12HRFormat });
                                        return <Tile className="lira-schedule-todays-events-card" style={{ 
                                            position: "relative",
                                            top: `-${index * 1}rem`,
                                            border: "2px solid rgb(100,100,100)",
                                            borderRadius: "0.5rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            backgroundColor: "#2d2d2d",
                                            cursor: "pointer",
                                            transform: "scale(.75)",
                                            transformOrigin: "top left",
                                            opacity: eventInFullPlaylistFeed ? "1" : "0.5",
                                            minHeight: "100px",
                                        }}
                                            onClick={() => {
                                                // console.log("event to highlight and scroll to",  event)
                                                setTodaysEventsSelectedEvent(event)
                                            }}
                                        >
                                            <div className="flex items-center gap-1 w-[calc(100%-55px)]">
                                                <p className="font-bold md:text-[20px] text-[12px] hidden sm:block">{event?.event + " - " + event?.name}</p>
                                                <p className="font-bold md:text-[20px] text-[12px] block sm:hidden">{event?.event}</p>
                                                <p className="font-bold md:text-[20px] text-[12px]">@</p>
                                                <p className="font-bold md:text-[20px] text-[12px]">{formattedTime?.replace("Invalid Date", "TBD")}</p>
                                            </div>
                                            <div className="md:text-[14px] text-[12px] items-center ml-[25px] gap-1 hidden sm:flex">
                                                <span>-</span>
                                                {event?.pcr !== "N/A" && <p className="md:text-[14px] text-[12px]">{event?.pcr},</p>}
                                                {event?.platform !== "N/A" && <p className="md:text-[14px] text-[12px]">{event?.platform},</p>}
                                                {event?.encoder !== "N/A" && <p className="md:text-[14px] text-[12px]">{event?.encoder ? event?.encoder : "No Encoder Selected"}</p>}
                                            </div>
                                            <div className="h-[5px] bg-green-500 absolute bottom-0 left-0"
                                                style={{
                                                    width: estimateEventLengths?.find(checkAgainst => checkAgainst?.[0]?.id === event?.id) ? `${eventsTimeRemainingBarPercent}%` : "0%",
                                                }}
                                            ></div>
                                            <div className="absolute top-[18px] right-2 w-[45px] h-[35px] flex justify-center items-center md:text-[18px] text-[12px]"
                                                style={{ 
                                                    color: eventInFullPlaylistFeed ? currentActivePlaylistFeed?.id === event?.id ? "#22c55e" : "gray" : "transparent", 
                                                    border: `1px solid ${eventInFullPlaylistFeed ? currentActivePlaylistFeed?.id === event?.id ? "#22c55e" : "gray" : "transparent"}`, 
                                                    borderRadius: "5px",
                                                    fontWeight: currentActivePlaylistFeed?.id === event?.id ? "700" : "400"
                                                }}
                                            >Live</div>
                                        </Tile>
                                    })}
                                </div>
                            </Tile>
                        }
                    </div>
                    <div id="lira-schedule-qc-feed-container" className="relative">
                        {/* <QCFeedWithAudioMeters 
                            localIP={localIP}
                            isFullScreen={isQCFeedFullScreen}
                            setIsFullScreen={setIsQCFeedFullScreen}
                            setCurrentHoveredPane={setCurrentHoveredPane}
                            liraTiersFromCookie={liraTiersFromCookie}
                            loggedInUserAccess={loggedInUserPageAccess}
                        /> */}
                    </div>
                </div>
                <ScheduleTable
                    scheduleEntries={scheduleEntries}
                    setScheduleEntries={setScheduleEntries}
                    todaysEventsSelectedEvent={todaysEventsSelectedEvent}
                    setCurrentHoveredPane={setCurrentHoveredPane}
                    isMainScheduleFullScreen={isMainScheduleFullScreen}
                    setIsMainScheduleFullScreen={setIsMainScheduleFullScreen}
                    localIP={localIP}
                    account_info_from_cookie={account_info_from_cookie}
                    active_account={active_account}
                    serverPermissions={serverPermissions}
                    whatPagesCanThisUserAccess={whatPagesCanThisUserAccess}
                    loggedInUserPageAccess={loggedInUserPageAccess}
                    liraTiersFromCookie={liraTiersFromCookie}
                    escapeFullScreenWillAlsoClearSelectedEntry={escapeFullScreenWillAlsoClearSelectedEntry}
                    setEscapeFullScreenWillAlsoClearSelectedEntry={setEscapeFullScreenWillAlsoClearSelectedEntry}
                    setEstimateEventLengths={setEstimateEventLengths}
                    setCurrentActivePlaylistFeed={setCurrentActivePlaylistFeed}
                    setFullPlaylistFeed={setFullPlaylistFeed}
                    scrollToFirstEntry={scrollToFirstEntry}
                    setScrollToFirstEntry={setScrollToFirstEntry}
                    isRightClickMenuOpen={isRightClickMenuOpen}
                    setIsRightClickMenuOpen={setIsRightClickMenuOpen}
                />
            </>
        </Theme>
    )
}

export function ScheduleTable({
    scheduleEntries,
    setScheduleEntries,
    // disableActionsBasedOffUserRole,
    todaysEventsSelectedEvent,
    setCurrentHoveredPane,
    localIP,
    account_info_from_cookie,
    active_account,
    isMainScheduleFullScreen,
    setIsMainScheduleFullScreen,
    maxWidthOverride,
    maxHeightOverride,
    disableFullScreenButton=false,
    serverPermissions,
    whatPagesCanThisUserAccess,
    loggedInUserPageAccess,
    liraTiersFromCookie,
    escapeFullScreenWillAlsoClearSelectedEntry,
    setEscapeFullScreenWillAlsoClearSelectedEntry,
    setEstimateEventLengths=() => {},
    setCurrentActivePlaylistFeed=() => {},
    setFullPlaylistFeed=() => {},
    scrollToFirstEntry=false,
    setScrollToFirstEntry=() => {},
    isRightClickMenuOpen,
    setIsRightClickMenuOpen,
}) {
    const maxFavoritesPerUser = 3;
    const [isRowColorsEnabled, setIsRowColorsEnabled] = useState(true);
    const [isStartTime12HRFormat, setIsStartTime12HRFormat] = useState(true);
    const [backupScheduleConfiguredFavorites, setBackupScheduleConfiguredFavorites] = useState([{
        name: "Favorite 1",
        enabledOnLoad: false,
        rules: [],
    }]);
    const [scheduleConfiguredFavorites, setScheduleConfiguredFavorites] = useState([{
        name: "Favorite 1",
        enabledOnLoad: false,
        rules: [],
    }]);

    const [favoriteEnabledInfo, setEnabledFavoriteInfo] = useState({
        isEnabled: false,
        favorite: null,
    });
    
    const DEFAULT_TICKET_GENERATION_INFO = {
        isOpen: false,
        FROM: account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username || "kflindevaldsen@liberty.edu",
        // TO: ["libertydev@service-now.com"],
        TO: ["liberty@service-now.com"],
        SUBJECT: "LIRA - Ticket Generation",
        Item: "IT Event Support",
        "Requested for": account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"]?.split("@")?.[0] : active_account?.username?.split("@")?.[0] || "",
        "Who needs to be informed of this request (email)?": "events@liberty.edu",
        "What is the date and start time of the event?": "",
        "What is the stop date and time of the event?": "",
        "What is the event set-up time?": "",
        "What is the event tear down time?": "",
        "What is the name of the event?": "",
        "Do you need Campus Production Support?": "No",
        "Do you need Sports Production Support?": "No",
        "Will this event be Live Streamed? (Outbound feed of live events to various platforms. Separate from Broadcast Support, this team is needed for any live video stream.)": "No",
        "LUTV (IPTV and Web Player)": "False",
        "Kaltura (Event Specific Web Player)": "False",
        "YouTube": "False",
        "Facebook (list page name or optional platform in comment box below)": "False",
        "Twitter": "False",
        "Recorded File": "False",
        "Live Other": "False",
        "If Streaming Destination is Other, please list here with platform Account info if necessary": "",
        "Do you need IT Event Support? (Athletics Game Day Support, WiFi, Event Phone, Scheduled Video Board Display, Printers, loaner laptops for events, Network Drops for events, Automated system usage pre-tests)": "No",
        "Green Hall Divider Wall": "False",
        "Wifi Deployment": "False",
        "Ethernet Drops": "False",
        "Live Microsoft Teams Events in Rooms": "False",
        "Printer Request": "False",
        "Digital Signage": "False",
        "Automated Video Board Content": "False",
        "Tapper/Badge Reader": "False",
        "Laptop": "False",
        "Routing and Switching": "False",
        "Athletic Game Support": "False",
        "Broadcast Engineering": "False",
        "Please provide additional details of IT event support needed": "",
        "Do you need Radio Support? (Radio engineering support for Liberty Flames Athletics)": "No",
        "Is the event on or off campus?": "",
        "What is the event type?": "Other",
        "If Other, please list the event type here": "",
        "WQLU": "False",
        "WRVL": "False",
        "Athletic Radio Stream": "False",
        "Wheeler": "False",
        "WLNI": "False",
        "Other": "False",
        "If Other, please list here": "",
        "What is the on air time?": "",
        "What is the off air time?": "",
        "What is the record start time?": "",
        "UUID": "",
        "Please provide any additional information that would help us meet your needs.": "",
    }

    const [ticketGenerationInfo, setTicketGenerationInfo] = useState({
        isOpen: false,
        FROM: account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username || "kflindevaldsen@liberty.edu",
        // TO: ["libertydev@service-now.com"],
        TO: ["liberty@service-now.com"],
        SUBJECT: "LIRA - Ticket Generation",
        Item: "IT Event Support",
        "Requested for": account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"]?.split("@")?.[0] : active_account?.username?.split("@")?.[0] || "",
        "Who needs to be informed of this request (email)?": "events@liberty.edu",
        "What is the date and start time of the event?": "",
        "What is the stop date and time of the event?": "",
        "What is the event set-up time?": "",
        "What is the event tear down time?": "",
        "What is the name of the event?": "",
        "Do you need Campus Production Support?": "No",
        "Do you need Sports Production Support?": "No",
        "Will this event be Live Streamed? (Outbound feed of live events to various platforms. Separate from Broadcast Support, this team is needed for any live video stream.)": "No",
        "LUTV (IPTV and Web Player)": "False",
        "Kaltura (Event Specific Web Player)": "False",
        "YouTube": "False",
        "Facebook (list page name or optional platform in comment box below)": "False",
        "Twitter": "False",
        "Recorded File": "False",
        "Live Other": "False",
        "If Streaming Destination is Other, please list here with platform Account info if necessary": "",
        "Do you need IT Event Support? (Athletics Game Day Support, WiFi, Event Phone, Scheduled Video Board Display, Printers, loaner laptops for events, Network Drops for events, Automated system usage pre-tests)": "No",
        "Green Hall Divider Wall": "False",
        "Wifi Deployment": "False",
        "Ethernet Drops": "False",
        "Live Microsoft Teams Events in Rooms": "False",
        "Printer Request": "False",
        "Digital Signage": "False",
        "Automated Video Board Content": "False",
        "Tapper/Badge Reader": "False",
        "Laptop": "False",
        "Routing and Switching": "False",
        "Athletic Game Support": "False",
        "Broadcast Engineering": "False",
        "Please provide additional details of IT event support needed": "",
        "Do you need Radio Support? (Radio engineering support for Liberty Flames Athletics)": "No",
        "Is the event on or off campus?": "",
        "What is the event type?": "Other",
        "If Other, please list the event type here": "",
        "WQLU": "False",
        "WRVL": "False",
        "Athletic Radio Stream": "False",
        "Wheeler": "False",
        "WLNI": "False",
        "Other": "False",
        "If Other, please list here": "",
        "What is the on air time?": "",
        "What is the off air time?": "",
        "What is the record start time?": "",
        "UUID": "",
        "Please provide any additional information that would help us meet your needs.": "",
    });

    const isAssignedScheduleEdit = useMemo(() => {
        if(liraTiersFromCookie?.includes("schedule-edit") || liraTiersFromCookie?.some(tier => ["developer"]?.includes(tier))) {
            return true
        } else {
            // if(window?.location?.href?.includes("localhost")) {
            //     return true
            // }
            return false
        }
    }, [liraTiersFromCookie])
    const isAssignedScheduleFormat = useMemo(() => {
        if(liraTiersFromCookie?.includes("schedule-format") || liraTiersFromCookie?.some(tier => ["developer"]?.includes(tier))) {
            return true
        } else {
            // if(window?.location?.href?.includes("localhost")) {
            //     return true
            // }
            return false
        }
    }, [liraTiersFromCookie])

    const [sortByKey, setSortByKey] = useState('date');
    const [isSortDirectionAscending, setIsSortDirectionAscending] = useState(true);
    const [filteredScheduleEntries, setFilteredScheduleEntries] = useState([])
    const [scheduleSearch, setScheduleSearch] = useState([""]);
    const [prevScheduleSearch, setPrevScheduleSearch] = useState([""]);
    const [isModalHeadersOpen, setIsModalHeadersOpen] = useState(false)
    const [isModalConfigureFavoritesInfo, setIsModalConfigureFavoritesInfo] = useState(false)
    const [modalSelectFavoriteInfo, setModalSelectFavoriteInfo] = useState({ isOpen: false })
    const [modalDeleteEvent, setModalDeleteEvent] = useState({
        isOpen: false,
        isDeletingAllFutureRecurrences: false,
    })
    const [selectedScheduleEntry, setSelectedScheduleEntry] = useState(null);
    const [currentEditedCell, setCurrentEditedCell] = useState({ row: null, column: null })
    const [scheduleSettingsModalInfo, setScheduleSettingsModalInfo] = useState({
        open: false,
    })
    const [editedCellDataWaitingForSaveOrCancel, setEditedCellDataWaitingForSaveOrCancel] = useState(null)
    const [colorRules, setColorRules] = useState({})
    const [userPreferences, setUserPreferences] = useState({})
    // const [newEventPossiblePlatforms, setNewEventPossiblePlatforms] = useState([
    //     "TBD",
    //     "ATHLETICS APP",
    //     "CBS Sports Network",
    //     "ESPN+",
    //     "ESPNU",
    //     "FACEBOOK",
    //     "Facebook+YouTube",
    //     "KALTURA",
    //     "KALTURA+FB+YouTube",
    //     "N/A",
    // ])
    const [newEventPossiblePlatforms, setNewEventPossiblePlatforms] = useState([])
    const [newEventPossibleEvents, setNewEventPossibleEvents] = useState([
        // "TBD",
        // "Athletics",
        // "Baseball",
        // "Campus Community",
        // "Class",
        // "Convocation",
        // "Field Hockey",
        // "Flames Central",
        // "Football",
        // "Men’s Basketball",
        // "Men’s D1 Hockey",
        // "Men’s D2 Hockey",
        // "Men’s D3 Hockey",
        // "Men’s Lacrosse",
        // "Men’s Soccer",
        // "Men’s Volleyball",
        // "N/A",
        // "School of Music",
        // "Softball",
        // "Special Event",
        // "Track & Field",
        // "Women’s Basketball",
        // "Women’s D1 Hockey",
        // "Women’s D2 Hockey",
        // "Women’s Lacrosse",
        // "Women’s Soccer",
        // "Women’s Swimming & Diving",
        // "Women’s Volleyball",
    ])
    const [newEventPossiblePCRs, setNewEventPossiblePCRs] = useState([
        // "TBD",
        // "FlyPack",
        // "MU-NEST",
        // "N/A",
        // "PCR-A",
        // "PCR-B",
        // "PCR-C",
        // "PCR-H",
        // "PCR-Z",
        // "PlaySight",
        // "Remote",
        // "SOB",
        // "SOM",
        // "WQLU",
    ])
    const [newEventPossibleEncoders, setNewEventPossibleEncoders] = useState([
        // "TBD",
        // "ATH 01",
        // "ATH 02",
        // "ATH 03",
        // "ENC 50",
        // "ENC ADHOC",
        // "ESPN ENC Nest",
        // "Golden",
        // "LTN1",
        // "LTN Nest",
        // "N/A",
    ])
    const [newEventPossibleProducers, setNewEventPossibleProducers] = useState([
        // "TBD",
        // "Annie",
        // "Bobby",
        // "Bruce",
        // "Byrd",
        // "Cora",
        // "CJ",
        // "Danielle",
        // "Jonah",
        // "Kate",
        // "Navage",
        // "N/A",
        // "Rett",
        // "Seth",
        // "Sheldon",
        // "Student",
        // "Warner",
    ])
    const [newEventPossibleEICs, setNewEventPossibleEICs] = useState([
        // "TBD",
        // "Al",
        // "Bradley",
        // "Chris",
        // "Cole",
        // "Josh",
        // "Kaleb",
        // "Kristian",
        // "Levi",
        // "Louis",
        // "Mark",
        // "N/A",
        // "Shawn",
        // "Stefan",
    ])
    const [newEventPossibleTMs, setNewEventPossibleTMs] = useState([
        // "TBD",
        // "Bradley",
        // "David",
        // "Doug",
        // "Jadzia",
        // "Joe",
        // "Julie",
        // "Monika",
        // "N/A",
        // "Pat Strawn",
        // "Stephanie",
    ])
    const [newEventPossibleLocations, setNewEventPossibleLocations] = useState([
        // "TBD",
        // "Art Gallery",
        // "Away",
        // "East Campus",
        // "Hancock Welcome Center 202",
        // "Indoor Practice Facility",
        // "Lahaye Event Space",
        // "Lahaye Ice Center",
        // "LaHaye Parking Lot",
        // "Liberty Arena",
        // "Liberty Baseball Stadium",
        // "Liberty Indoor Track Complex",
        // "Liberty Natatorium",
        // "Liberty Softball Stadium",
        // "Montview Ballroom",
        // "N/A",
        // "Osborne Stadium",
        // "Outside Dining Hall",
        // "Prayer Chapel",
        // "Propst Arena",
        // "Rutledge Office",
        // "Scottsdale Plaza Resort",
        // "SOB Towns Auditorium",
        // "SOM Concert Hall",
        // "Studio A",
        // "Studio B",
        // "TBD",
        // "Tower Theater",
        // "TRBC",
        // "Reber Thomas Dining",
        // "Vines Center",
        // "Williams Stadium",
        // "WQLU",
    ])
    const [newEventPossibleCoverages, setNewEventPossibleCoverages] = useState([
        // "TBD",
        // "YES",
        // "NO",
        // "CANC",
        // "PEND",
        // "PSITE",
    ])
    const [newEventPossibleORGs, setNewEventPossibleORGs] = useState([
        // "TBD",
        // "AC",
        // "CP",
        // "FC",
        // "SP",
    ])
    const [newEventPossibleClients, setNewEventPossibleClients] = useState([
        // "TBD",
        // "ACAD",
        // "CLAS",
        // "CLUB",
        // "EXT",
        // "FC",
        // "IMAG",
        // "INT",
        // "NCAA",
        // "N/A",
        // "OSD",
        // "RADIO",
        // "SA",
    ])
    const [newEventPossibleTypes, setNewEventPossibleTypes] = useState([
        // "TBD",
        // "Away Game",
        // "Class",
        // "Home Game",
        // "IMAG",
        // "IMAG/Record",
        // "Live",
        // "N/A",
        // "Playout",
        // "Record",
        // "Set",
        // "Studio Show",
    ])
    const [enabledColumns, setEnabledColumns] = useState([
        COLUMN_VALUES_CASE_SENSITIVE["Day"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Date"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Start Time"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Event"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Name"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Location"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["PCR"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Platform"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Encoder"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Ticket"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Producer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["EIC"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Field Engineer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Tech Manager"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Coverage"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Notes"].upper,
    ]);
    const [allPossibleColumnsInOrder, setAllPossibleColumnsInOrder] = useState([
        COLUMN_VALUES_CASE_SENSITIVE["Day"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Date"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Start Time"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Event"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Name"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Location"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["PCR"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Platform"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Encoder"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Ticket"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Producer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["EIC"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Field Engineer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Tech Manager"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Coverage"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["ORG"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Client"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Type"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Notes"].upper,
    ]);

    const [defaultEnabledColumns, setDefaultEnabledColumns] = useState([
        COLUMN_VALUES_CASE_SENSITIVE["Day"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Date"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Start Time"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Event"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Name"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Location"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["PCR"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Platform"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Encoder"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Ticket"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Producer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["EIC"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Field Engineer"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Tech Manager"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Coverage"].upper,
        COLUMN_VALUES_CASE_SENSITIVE["Notes"].upper,
    ]);
    const [modalEvent, setModalEvent] = useState(DEFAULT_NEW_EVENT_KEY_VALUES);
    const [LastInstanceOfModalEvent, setLastInstanceOfModalEvent] = useState(DEFAULT_NEW_EVENT_KEY_VALUES);

    const [columnsOrder, setColumnsOrder] = useState([...allPossibleColumnsInOrder]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function formatDate(dateString) {
        //^ mm/dd/yyyy
        const date = new Date(dateString);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }

    function getDayOfWeek(dateString) {
        const [month, day, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return daysOfWeek[date.getDay()];
      }
    
    const handleInputChange = debounce((value) => {
        const search_params = value?.split(" ")
        // console.log("Search Params", search_params)
        setScheduleSearch(search_params)
        setPrevScheduleSearch(scheduleSearch)
    }, 300);

    function handleDragEnd(event) {
        const {active, over} = event;
        
        if (active?.id !== over?.id) {
            setColumnsOrder((columns) => {
            console.log(columns, active, over);
            const oldIndex = columns.findIndex((column) => column === active?.id);
            const newIndex = columns.findIndex((column) => column === over?.id);
            console.log(oldIndex, newIndex);
            return arrayMove(columns, oldIndex, newIndex);
          });
        }
      }
    
    function formatTicketGenerationDateString(event, hoursToAdd = 0) {
        if(event?.date === "TBD" || event?.["start time"] === "TBD") {
            return formatTicketGenerationDateString({
                date: new Date().toISOString().split("T")[0],
                "start time": "00:00:00"
            })
        }
        const date = new Date(event?.date);
        const pad = (num) => String(num).padStart(2, '0');

        // const year = date.getFullYear();
        // const month = pad(date.getMonth() + 1);
        // const day = pad(date.getDate());

        let hours = parseInt(event?.["start time"]?.split(":")[0]) + hoursToAdd + 0;
        let minutes = parseInt(event?.["start time"]?.split(":")[1]);

        // Add hoursToAdd to the date object
        date.setHours(hours, minutes);

        // Recalculate date parts
        const newYear = date.getFullYear();
        const newMonth = pad(date.getMonth() + 1);
        const newDay = pad(date.getDate());
        const newHours = pad(date.getHours());
        const newMinutes = pad(date.getMinutes());

        return `${newYear}-${newMonth}-${newDay} ${newHours}:${newMinutes}:00`;
    }
      

    /**
     * Asynchronously handles the creation of recurring events based on specified recurrence rules.
     * 
     * @param {Object} eventWithRecurrenceRules - The event object containing recurrence rules and related properties.
     * @returns {Array} - An array of newly created recurring events.
     * 
     * This function processes an event object with recurrence rules and generates a series of recurring events. It supports two types of recurrence end rules:
     * - End by Date: Recurs until a specified end date.
     * - End after a certain number of occurrences.
     * 
     * The function also accounts for weekly recurrences by adjusting the increment of added events.
     * 
     * Steps:
     * 1. Initialize the start date and end date from the event object.
     * 2. Determine the count increment based on the recurrence rule (weekly or daily).
     * 3. Generate recurring events based on the specified end rule type:
     *    - For end by date, create events until the end date is reached.
     *    - For end after a specific number of occurrences, create that many events.
     * 4. Each new event is assigned a unique ID, and the date and day are formatted for readability.
     * 5. The generated events are returned as an array.
     */
    function handleAddingEventRecurrences(eventWithRecurrenceRules) {
        console.log("Adding Recurrences via eventWithRecurrenceRules", eventWithRecurrenceRules);

        if(eventWithRecurrenceRules?.recurrenceRule?.toLowerCase() === NEW_EVENT_RECURRENCE_RULES?.["Event Does Not Repeat"]?.toLowerCase()) return
        if(!eventWithRecurrenceRules?.endRecurrenceRuleValue) return []

        let startDate = new Date(eventWithRecurrenceRules.date);
        const endDate = new Date(eventWithRecurrenceRules.endRecurrenceRuleValue);
        const events = [];

        let countAddedEachLoop = 1
        if(eventWithRecurrenceRules?.recurrenceRule?.toLowerCase() === NEW_EVENT_RECURRENCE_RULES?.["Weekly"]?.toLowerCase()) {
            countAddedEachLoop = 7
        }

        if(eventWithRecurrenceRules?.endRecurrenceRuleType?.toLowerCase() === NEW_EVENT_END_RECURRENCE_RULES["Date"]?.toLowerCase()) {
            // console.log("End Recurrence Rule Type: " + eventWithRecurrenceRules?.endRecurrenceRuleType);
            const recurrenceLink = `recurrence-${eventWithRecurrenceRules?.id}`;
            for(let i = 1; i <= 365; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + countAddedEachLoop);

                let currentDateEpoch = new Date(startDate)?.getTime();
                currentDateEpoch += (86400000 * countAddedEachLoop);

                const event = { ...eventWithRecurrenceRules };
                event["recurrenceLink"] = recurrenceLink;
                event["id"] = uuid();
                event.date = formatDate(currentDate);
                console.log("Test", currentDate, formatDate(currentDate))
                event.day = currentDate.toLocaleDateString('en-US', { weekday: 'long' });

                // console.log("CHECK DATE", currentDateEpoch, endDate.getTime() + 86400000, currentDateEpoch >= endDate.getTime() + 86400000);

                if(currentDateEpoch >= endDate.getTime() + 86400000) break

                events.push(event);
                startDate = currentDate;
            }
        } else if(eventWithRecurrenceRules?.endRecurrenceRuleType?.toLowerCase() === NEW_EVENT_END_RECURRENCE_RULES["After"]?.toLowerCase()) {
            for (let i = 1; i <= eventWithRecurrenceRules?.endRecurrenceRuleValue; i++) {
                const recurrenceLink = `recurrence-${eventWithRecurrenceRules?.id}`;
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + (i * countAddedEachLoop));
    
                const event = { ...eventWithRecurrenceRules };
                event["recurrenceLink"] = recurrenceLink;
                event["id"] = uuid();
                event.date = formatDate(currentDate);
                event.day = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    
                events.push(event);
            }
        }

        // console.log("New Recurring Events", events);
        return events
    }

    async function handleCreatingEvent(newEventData) {
        const newEventDataWithId = {
            ...newEventData,
            id: uuid(),
            createdDateTime: new Date(),
        }

        console.log("Creating New Event", newEventDataWithId);

        const recurringEvents = handleAddingEventRecurrences(newEventDataWithId)

        console.log("Returned New Recurring Events", recurringEvents);
        
        // return
        const res = await fetch(`https://${localIP}/schedule/create-event`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newEventDataWithId)
        })
        const user = account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username
        if(!res.ok) {
            alert("Cannot create new event - Status Code: " + res.status)
            sendServerNewLog(`[INFO] Error (Status Code ${res?.status || "404"}): ${user} - failed to create new event ${JSON.stringify(newEventDataWithId)}`, "schedule")
            return
        }
        sendServerNewLog(`[INFO] Success (Status Code ${res?.status || "200"}): ${user} - created new event ${JSON.stringify(newEventDataWithId)}`, "schedule")

        const data1 = await res.json()
        console.log("Data without new recurrences", data1);
        setScheduleEntries([...data1])

        if(!recurringEvents ||recurringEvents?.length < 1) return

        // console.log("Recurring Events", recurringEvents);
        
        recurringEvents?.forEach(async (recurringEvent) => {
            const res2 = await fetch(`https://${localIP}/schedule/create-event`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(recurringEvent)
            })
            if(!res2.ok) {
                alert("Cannot create new event - Status Code: " + res.status)
                sendServerNewLog(`[INFO] Error (Status Code ${res?.status || "404"}): ${user} - failed to create new recurring event ${JSON.stringify(recurringEvent)}`, "schedule")
                return
            }
            const data2 = await res2.json()
            console.log("Data with new recurrences", data2);

            setScheduleEntries([...data2])
        })
        sendServerNewLog(`[INFO] Success (Status Code ${res?.status || "200"}): ${user} - created new recurrences ${JSON.stringify(recurringEvents)}`, "schedule")
    }

    async function handleEditingEvent(editedEventData) {
        console.log("Editing Event", editedEventData);

        if(!editedEventData) {
            console.log("editedEventData is null");
            return
        }

        const { recurrenceLink, ...rest } = editedEventData

        const res = await fetch(`https://${localIP}/schedule/edit-event`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(rest)
        })
        const user = account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username
        if(!res.ok) {
            alert("Cannot edited event - Status Code: " + res.status)
            sendServerNewLog(`[INFO] Error (Status Code ${res?.status || "404"}): ${user} - failed to edit event ${JSON.stringify(rest)}`, "schedule")
            return
        }
        sendServerNewLog(`[INFO] Success (Status Code ${res?.status || "200"}): ${user} - editing event ${JSON.stringify(rest)}`, "schedule")

        const data = await res.json()
        // console.log("Data", data);
        setScheduleEntries([...data])
        setCurrentEditedCell({ row: null, column: null })
    }
    
    async function handleDeletingEvent(eventForDeleting, isDeletingAllFutureRecurrences=false) {
        console.log("Creating New Event", eventForDeleting, "isDeletingAllFutureRecurrences", isDeletingAllFutureRecurrences);

        if(isDeletingAllFutureRecurrences) {
            const recurrencesToDelete = scheduleEntries?.filter((event) => event?.recurrenceLink === eventForDeleting?.recurrenceLink && new Date(event?.date)?.getTime() > new Date(eventForDeleting?.date)?.getTime() - 86400000)
            console.log("Recurrences To Delete", recurrencesToDelete);
            const idsForDeleting = recurrencesToDelete?.map(event => event?.id)
            const res = await fetch(`https://${localIP}/schedule/multiple-delete-events`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ids: idsForDeleting
                })
            })
            const user = account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username
            if(!res.ok) {
                alert("Cannot delete event - Status Code: " + res.status)
                sendServerNewLog(`[INFO] Error (Status Code ${res?.status || "404"}): ${user} - failed to delete event ${JSON.stringify(recurrencesToDelete)}`, "schedule")
                return
            }
            sendServerNewLog(`[INFO] Success (Status Code ${res?.status || "200"}): ${user} - deleted event ${JSON.stringify(recurrencesToDelete)}`, "schedule")
            const data = await res.json()
            console.log("Data after deleting recurrences", data);
            setScheduleEntries([...data])
        } else {
            const res = await fetch(`https://${localIP}/schedule/delete-event/${eventForDeleting?.id}`, {
                method: "DELETE",
            })
            const user = account_info_from_cookie?.["username"] ? account_info_from_cookie?.["username"] : active_account?.username
            if(!res.ok) {
                alert("Cannot delete event - Status Code: " + res.status)
                sendServerNewLog(`[INFO] Error (Status Code ${res?.status || "404"}): ${user} - failed to delete event ${JSON.stringify(eventForDeleting)}`, "schedule")
                return
            }
            sendServerNewLog(`[INFO] Success (Status Code ${res?.status || "200"}): ${user} - deleted event ${JSON.stringify(eventForDeleting)}`, "schedule")
            const data = await res.json()
            console.log("Data after deleting single event", data);
            setScheduleEntries([...data])
        }

        setCurrentEditedCell({ row: null, column: null })
        document.activeElement.blur();
    }

    // Utility function to compare values based on data type
    const customCompare = (valueA, valueB) => {
        // Handle null or undefined values
        if (valueA == null) return -1;
        if (valueB == null) return 1;

        // Convert to numbers if possible
        const numA = parseFloat(valueA);
        const numB = parseFloat(valueB);
        const isNumeric = !isNaN(numA) && !isNaN(numB);

        // Parse dates if applicable
        const parseDate = (dateStr) => {
            // Implement date parsing logic as per your date format
            // Example:
            return new Date(dateStr);
        };

        // Compare based on data types
        if (sortByKey === 'date') {
            const dateA = parseDate(valueA);
            const dateB = parseDate(valueB);
            if (dateA && dateB) return dateA - dateB;
        } else if (isNumeric) {
            return numA - numB;
        }

        // Default to string comparison
        return valueA.localeCompare(valueB);
    };

    async function sendServerNewLog(logMessage, server) {
        const date = new Date()
        const year = date.getFullYear()
        let month = String(date.getMonth() + 1)
        if (month.length === 1) month = `0${month}`
        let day = String(date.getDate())
        if (day.length === 1) day = `0${day}`
        const formattedDate = `${year}-${month}-${day}`
        let hours = String(date.getHours())
        if (hours.length === 1) hours = `0${hours}`
        let minutes = String(date.getMinutes())
        if (minutes.length === 1) minutes = `0${minutes}`
        let seconds = String(date.getSeconds())
        if (seconds.length === 1) seconds = `0${seconds}`
        const formattedTime = `${hours}:${minutes}:${seconds}`
        const finalLog = `${formattedDate} ${formattedTime} ${logMessage}`
        const res = await fetch(`https://${localIP}/${server}/new-log`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "log": finalLog
            })
        })
    }

    // const displayNotificationRef = useRef(null);
    const [displayingCarbonNotificationInfo, setDisplayingCarbonNotificationInfo] = useState({
        isOpen: false,
        message: null,
        type: null,
    });

    function displayCarbonNotification(message="Default Error Message", type="Error") {
       setDisplayingCarbonNotificationInfo({
           isOpen: true,
           message: message,
           type: type
       });
    }

    const sortByKeyFunction = (array, key, isSortDirectionAscending) => {
        // console.log("sortByKeyFunction", sortByKey, isSortDirectionAscending);
        if (key?.toLowerCase() === "date") {
            function dateCompare(a, b) {
                // console.log("Event 1", a)
                // console.log("Event 2", b)
                const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
                const timeRegex = /^\d{2}:\d{2}$/;

                if (dateRegex.test(a.date) && dateRegex.test(b.date)) {
                    // console.log("Date Regex Test Passed");
                    // console.log("date string A", a.date)
                    // console.log("date string b", b.date)
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);

                    const startTimeA = a?.["start time"] === "TBD" ? "23:59" : a?.["start time"];
                    const startTimeB = b?.["start time"] === "TBD" ? "23:59" : b?.["start time"];

                    // console.log("startTimeA", startTimeA)
                    // console.log("startTimeB", startTimeB)

                    if (timeRegex.test(startTimeA) && timeRegex.test(startTimeB)) {
                        // console.log("Time Regex Test Passed");
                        // console.log("time string A", a.date)
                        // console.log("time string b", b.date)
                        const [hoursA, minutesA] = startTimeA.split(':').map(Number);
                        const [hoursB, minutesB] = startTimeB.split(':').map(Number);

                        const timeA = new Date(dateA.setHours(hoursA, minutesA));
                        const timeB = new Date(dateB.setHours(hoursB, minutesB));

                        return timeA - timeB;
                    }

                    return dateA - dateB;
                }
            }

            if (isSortDirectionAscending) {
                return array.slice().sort((a, b) => dateCompare(a, b));
            } else {
                return array.slice().sort((a, b) => dateCompare(b, a));
            }
        } else {
            if (isSortDirectionAscending) {
                return array.slice().sort((a, b) => customCompare(a[key], b[key]));
            } else {
                return array.slice().sort((a, b) => customCompare(b[key], a[key]));
            }
        }
    };

    const handleKeyDown = useCallback((event) => {
        const shortCutCombo = (event.ctrlKey || event.metaKey) && event.key === 'e'
        // console.log("shortCutCombo", shortCutCombo, event.key);

        if(shortCutCombo && !modalEvent?.open) {
            event.preventDefault()
            if(!selectedScheduleEntry) {
                if (document?.querySelectorAll(".cds--search-input").length > 0) {
                    const anyInputNotFocused = Array.from(document.querySelectorAll(".cds--search-input")).some(input => !input.matches(":focus"));
                    if (anyInputNotFocused) {
                        console.log("At least one input is not focused");
                        // alert("Please select an event")
                        displayCarbonNotification("Please select an event to edit", "Error")
                        return
                    } else {
                        // console.log("All inputs are focused");
                        return
                    }
                } else {
                    console.log("No inputs found with the class .cds--search-input");
                    // alert("Please select an event")
                    displayCarbonNotification("Please select an event to edit", "Error")
                    return
                }
            }
            // console.log(selectedScheduleEntry)
            setModalEvent({
                ...selectedScheduleEntry,
                open: true,
                edit: true,
            })
        }
        if(!currentEditedCell?.column) {
            if(event.key === "Escape") {
                setSelectedScheduleEntry(null)
                setCurrentEditedCell({
                    row: null,
                    column: null,
                })
                document.activeElement.blur();
            }
        }
        // console.log("Current Edited Cell", currentEditedCell);
        if(event.key === "Escape") {
            event.preventDefault();
            // console.log("Clearing Edited Data", currentEditedCell, editedCellDataWaitingForSaveOrCancel);
            const foundPrevEntryData = scheduleEntries?.find(entry => entry?.id === editedCellDataWaitingForSaveOrCancel?.id);
            // console.log(foundPrevEntryData);
            setScheduleEntries(prev => {
                return prev.map(entry => {
                    if(entry?.id === editedCellDataWaitingForSaveOrCancel?.id) {
                        return {
                            ...entry,
                        }
                    }
                    return entry
                })
            })
            setSelectedScheduleEntry(null)
            setCurrentEditedCell({
                row: null,
                column: null,
            })
            setModalEvent({
                open: false,
                edit: false,
            })
            if(!foundPrevEntryData?.hasOwnProperty(currentEditedCell?.column?.toLowerCase())) {
                document.activeElement.value = "";
            } else if(foundPrevEntryData) {
                document.activeElement.value = foundPrevEntryData?.[currentEditedCell?.column?.toLowerCase()];
            }
            document.activeElement.blur();
        }
        if((event.key === "Tab" || event.key === "Enter") && currentEditedCell?.column) {
            event.preventDefault();
            // console.log("Saving Data", editedCellDataWaitingForSaveOrCancel);
            handleEditingEvent(editedCellDataWaitingForSaveOrCancel);
            setSelectedScheduleEntry(null)
            setCurrentEditedCell({
                row: null,
                column: null,
            })
        }
        
    }, [currentEditedCell, editedCellDataWaitingForSaveOrCancel]);      

    //^ Use useEffect to add event listener when component mounts
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        // document.addEventListener('keydown', handleTildaKeyDown);

        //^ Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);

            // document.removeEventListener('keydown', handleTildaKeyDown);
        };
    }, [handleKeyDown]);

    const sortedEntries = sortByKeyFunction(filteredScheduleEntries, sortByKey, isSortDirectionAscending);

    // console.log(JSON.stringify(colorRules))
    useEffect(() => {
        async function fetchColorRules() {
            const res = await fetch(`https://${localIP}/lira/schedule/view-color-rules`)
            if(!res.ok) return
            const data = await res.json()
            setColorRules(data)
        }
        async function fetchNewEventSelects() {
            const res = await fetch(`https://${localIP}/schedule/view-new-event-selects`)
            if(!res.ok) return
            const data = await res.json()
            // console.log("new event selects", data)
            if(data?.hasOwnProperty("newEventPossibleClients")) {
                setNewEventPossibleClients(data?.newEventPossibleClients)
            }
            if(data?.hasOwnProperty("newEventPossibleCoverages")) {
                setNewEventPossibleCoverages(data?.newEventPossibleCoverages)
            }
            if(data?.hasOwnProperty("newEventPossibleEICs")) {
                setNewEventPossibleEICs(data?.newEventPossibleEICs)
            }
            if(data?.hasOwnProperty("newEventPossibleEncoders")) {
                setNewEventPossibleEncoders(data?.newEventPossibleEncoders)
            }
            if(data?.hasOwnProperty("newEventPossibleEvents")) {
                setNewEventPossibleEvents(data?.newEventPossibleEvents)
            }
            if(data?.hasOwnProperty("newEventPossibleLocations")) {
                setNewEventPossibleLocations(data?.newEventPossibleLocations)
            }
            if(data?.hasOwnProperty("newEventPossibleORGs")) {
                setNewEventPossibleORGs(data?.newEventPossibleORGs)
            }
            if(data?.hasOwnProperty("newEventPossiblePCRs")) {
                setNewEventPossiblePCRs(data?.newEventPossiblePCRs)
            }
            if(data?.hasOwnProperty("newEventPossiblePlatforms")) {
                setNewEventPossiblePlatforms(data?.newEventPossiblePlatforms)
            }
            if(data?.hasOwnProperty("newEventPossibleProducers")) {
                setNewEventPossibleProducers(data?.newEventPossibleProducers)
            }
            if(data?.hasOwnProperty("newEventPossibleTMs")) {
                setNewEventPossibleTMs(data?.newEventPossibleTMs)
            }
            if(data?.hasOwnProperty("newEventPossibleTypes")) {
                setNewEventPossibleTypes(data?.newEventPossibleTypes)
            }
        }
        
        fetchColorRules()
        fetchNewEventSelects()

        return () => {
            setScheduleSearch([""]);
            setPrevScheduleSearch([""]);
            setFilteredScheduleEntries([]);
            setLastInstanceOfModalEvent({});
            setColorRules({});
            setCurrentEditedCell({ row: null, column: null });
            setNewEventPossiblePlatforms([]);
            setNewEventPossibleProducers([]);
            setNewEventPossibleTMs([]);
            setNewEventPossibleTypes([]);
            setNewEventPossibleClients([]);
            setNewEventPossibleCoverages([]);
            setNewEventPossibleEICs([]);
            setNewEventPossibleEncoders([]);
            setNewEventPossibleEvents([]);
            setNewEventPossibleLocations([]);
            setNewEventPossibleORGs([]);
            setNewEventPossiblePCRs([]);
        }
    }, [])

    const handleHeaderClick = useCallback((event) => {
        if(event.target?.closest?.("th")?.classList?.contains("schedule-table-header")) {
            if(event.target?.closest("th")?.getAttribute("data-header")) {
                setSortByKey(event.target?.closest("th")?.getAttribute("data-header"))
            }
        }
    }, []);

    //^ Use useEffect to add event listener when component mounts
    useEffect(() => {
        document.addEventListener('click', handleHeaderClick);

        //^ Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleHeaderClick);
        };
    }, [handleHeaderClick]);

    useEffect(() => {
        if(selectedScheduleEntry) setEscapeFullScreenWillAlsoClearSelectedEntry(true)
        else setEscapeFullScreenWillAlsoClearSelectedEntry(false)
    }, [selectedScheduleEntry])

    useEffect(() => {
        if(!modalEvent?.open) return
        if(JSON.stringify(LastInstanceOfModalEvent) === JSON.stringify(modalEvent)) return
        setLastInstanceOfModalEvent(modalEvent)
        const ticketRegex = /^TKT\d{6,8}$/
        if(
            !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Name"].lower]
            || !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Event"].lower]
            || !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Date"].lower]
            || !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Start Time"].lower]
            // || !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Platform"].lower]
            // || !modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["PCR"].lower]
            || (modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Ticket"].lower]?.length > 0 && !ticketRegex.test(modalEvent?.[COLUMN_VALUES_CASE_SENSITIVE["Ticket"].lower]))
        ) {
            // console.log("create disabled", modalEvent)
            setModalEvent(prev => {
                return {
                    ...prev,
                    createDisabled: true
                }
            })
        } else {
            setModalEvent(prev => {
                return {
                    ...prev,
                    createDisabled: false
                }
            })
        }
    }, [modalEvent, LastInstanceOfModalEvent])

    useEffect(() => {
        let formattedByHeader = {}
        console.log("selected favorite", modalSelectFavoriteInfo?.index)
        //* Check only first index of favorites
        scheduleConfiguredFavorites?.[modalSelectFavoriteInfo?.index]?.rules?.forEach(singleRule => {
            const { header, rule, value } = singleRule
            console.log(header, rule, value)
            if(!formattedByHeader[header?.toLowerCase()]) formattedByHeader[header?.toLowerCase()] = {}
            if(rule?.toLowerCase() === "contains") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("contains")) formattedByHeader[header?.toLowerCase()].contains = []
                if(Array.isArray(value)) formattedByHeader[header?.toLowerCase()].contains = value
                else formattedByHeader[header?.toLowerCase()]?.contains.push(value?.toLowerCase())
            } else if(rule?.toLowerCase() === "does not contain") {
                if(!formattedByHeader[header?.toLowerCase()]["does not contain"]) formattedByHeader[header?.toLowerCase()]["does not contain"] = []
                if(Array.isArray(value)) formattedByHeader[header?.toLowerCase()]["does not contain"] = value
                else formattedByHeader[header?.toLowerCase()]?.["does not contain"].push(value?.toLowerCase())
            } else if(rule?.toLowerCase() === "blank") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("blank")) formattedByHeader[header?.toLowerCase()]["blank"] = true
            } else if(rule?.toLowerCase() === "not blank") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("not blank")) formattedByHeader[header?.toLowerCase()]["not blank"] = true
            } else if(rule?.toLowerCase() === "before now") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("before now")) formattedByHeader[header?.toLowerCase()]["before now"] = true
            } else if(rule?.toLowerCase() === "hide past events") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("hide past events")) formattedByHeader[header?.toLowerCase()]["hide past events"] = true
            } else if(rule?.toLowerCase() === "next 7 days") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("next 7 days")) formattedByHeader[header?.toLowerCase()]["next 7 days"] = true
            } else if(rule?.toLowerCase() === "next 30 days") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("next 30 days")) formattedByHeader[header?.toLowerCase()]["next 30 days"] = true
            } else if(rule?.toLowerCase() === "this month") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("this month")) formattedByHeader[header?.toLowerCase()]["this month"] = true
            } else if(rule?.toLowerCase() === "next month") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("next month")) formattedByHeader[header?.toLowerCase()]["next month"] = true
            } else if(rule?.toLowerCase() === "last 7 days") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("last 7 days")) formattedByHeader[header?.toLowerCase()]["last 7 days"] = true
            } else if(rule?.toLowerCase() === "last 30 days") {
                if(!formattedByHeader[header?.toLowerCase()].hasOwnProperty("last 30 days")) formattedByHeader[header?.toLowerCase()]["last 30 days"] = true
            }
        })
        console.log("formattedByHeader", formattedByHeader);

        const menPattern = new RegExp("\\bmen\\b");
        const womenPattern = new RegExp("\\bwomen\\b");
        const datePattern = new RegExp("\\b\\d{1,2}/\\d{1,2}/\\d{4}\\b");

        const filteredFromFavorites = scheduleEntries?.filter(entry => {
            if (scheduleConfiguredFavorites?.length < 1 || !favoriteEnabledInfo?.isEnabled) return true;

            return Object.entries(formattedByHeader)?.every(([header, rule]) => {
                const entryValue = entry?.[header]?.toLowerCase();
                // console.log(`Checking entry for header ${header}: ${entryValue}`);

                const checkPattern = (headerValue, searchValue) => {
                    // console.log("checkPattern", headerValue, searchValue);
                    if (menPattern.test(searchValue)) {
                        // console.log("checkAgainstValue", headerValue);
                        return menPattern.test(headerValue) && !womenPattern.test(headerValue);
                    } else if (womenPattern.test(searchValue)) {
                        return womenPattern.test(headerValue) && !menPattern.test(headerValue);
                    } else {
                        if (datePattern.test(headerValue)) {
                            return headerValue.startsWith(searchValue);
                        }
                        return headerValue.includes(searchValue);
                    }
                };
                const currentDate = new Date();

                const nextSevenDays = new Date();
                nextSevenDays.setDate(currentDate.getDate() + 7);
               
                const nextThirtyDays = new Date();
                nextThirtyDays.setDate(currentDate.getDate() + 30);
                
                const nextMonth = new Date();
                nextMonth.setMonth(currentDate.getMonth() + 1)

                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(currentDate.getDate() - 7);

                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(currentDate.getDate() - 30);

                const beforeNowPasses = header === "date" && formattedByHeader["date"]["before now"] ? !isNaN(new Date(entryValue).getTime()) && new Date(entryValue).getTime() < currentDate.getTime() : true;
                const hidePastEventsPasses = header === "date" && formattedByHeader["date"]["hide past events"] ? !isNaN(new Date(entryValue).getTime()) && new Date(entryValue).getTime() >= currentDate.setHours(0,0,0,0) : true;
                const next7DaysPasses = header === "date" && formattedByHeader["date"]["next 7 days"] ? !isNaN(new Date(entryValue).getTime()) && new Date(entryValue).getTime() >= currentDate.getTime() && new Date(entryValue).getTime() <= nextSevenDays.getTime(): true;
                const next30DaysPasses = header === "date" && formattedByHeader["date"]["next 30 days"] ? !isNaN(new Date(entryValue).getTime()) && new Date(entryValue).getTime() >= currentDate.getTime() && new Date(entryValue).getTime() <= nextThirtyDays.getTime() : true;
                const thisMonthPasses = header === "date" && formattedByHeader["date"]["this month"] 
                ? !isNaN(new Date(entryValue).getTime()) 
                    && new Date(entryValue).getFullYear() === currentDate.getFullYear() 
                    && new Date(entryValue).getMonth() === currentDate.getMonth() 
                : true;
                const nextMonthPasses = header === "date" && formattedByHeader["date"]["next month"] 
                    ? !isNaN(new Date(entryValue).getTime())
                        && new Date(entryValue).getFullYear() === nextMonth.getFullYear()
                        && new Date(entryValue).getMonth() === nextMonth.getMonth()
                    : true;
                const last7DaysPasses = header === "date" && formattedByHeader["date"]["last 7 days"]
                    ? !isNaN(new Date(entryValue).getTime())
                        && new Date(entryValue).getTime() >= sevenDaysAgo.getTime()
                        && new Date(entryValue).getTime() <= currentDate.getTime()
                    : true;
                const last30DaysPasses = header === "date" && formattedByHeader["date"]["last 30 days"]
                    ? !isNaN(new Date(entryValue).getTime())
                        && new Date(entryValue).getTime() >= thirtyDaysAgo.getTime()
                        && new Date(entryValue).getTime() <= currentDate.getTime()
                    : true;
                // Check the 'contains' rules
                const containsPasses = rule?.contains?.length > 0 ?
                    rule.contains.some(ruleCheck => {
                        const ruleCheckLower = ruleCheck?.toLowerCase();
                        let result
                        if(["event", "location", "pcr", "platform", "encoder", "producer", "eic", "field engineer", "tech manager", "coverage", "org", "client", "type"].includes(header?.toLowerCase())) {
                            result = entryValue && entryValue === ruleCheckLower;
                        } else {
                            result = entryValue && (ruleCheckLower.includes(entryValue) || entryValue.includes(ruleCheckLower));
                        }
                        if (result) {
                            return checkPattern(entry?.[header]?.toLowerCase(), entryValue);
                        }
                        // console.log(`Contains check - Entry[${header}]: ${entryValue}, Rule: ${ruleCheckLower}, Result: ${result}`);
                        return result;
                    }) : true; // If no 'contains' rules, default to true

                // Check the 'does not contain' rules
                const doesNotContainPasses = rule?.['does not contain']?.length > 0 ?
                    rule['does not contain'].every(ruleCheck => {
                        const ruleCheckLower = ruleCheck?.toLowerCase();
                        const result = entryValue && (!ruleCheckLower.includes(entryValue) && !entryValue.includes(ruleCheckLower));
                        const patternResult = !checkPattern(entryValue, ruleCheckLower); // Ensure it does not match the pattern
                        const finalResult = result && patternResult; // Combine both results
                        // console.log(`Does not contain check - Entry[${header}]: ${entryValue}, Rule: ${ruleCheckLower}, Result: ${finalResult}`);
                        return finalResult;
                    }) : true; // If no 'does not contain' rules, default to true

                let blankPasses = true
                if(rule?.blank) {
                    blankPasses = ["tbd", "n/a", ""]?.includes(entryValue)
                }
                
                let notBlankPasses = true
                if(rule?.["not blank"]) {
                    notBlankPasses = !["tbd", "n/a", ""]?.includes(entryValue)
                }
                
                // console.log("Blank passes", blankPasses);
                // console.log(`Header ${header} entryValue: ${entryValue} passes: ${containsPasses && doesNotContainPasses && blankPasses}`);

                // console.log(`Header ${header} passes: ${containsPasses && doesNotContainPasses && blankPasses && notBlankPasses}`);
                return containsPasses && doesNotContainPasses && blankPasses && notBlankPasses && beforeNowPasses && hidePastEventsPasses && next7DaysPasses && next30DaysPasses && thisMonthPasses && nextMonthPasses && last7DaysPasses && last30DaysPasses;
            });
        });

        console.log("filteredFromFavorites", filteredFromFavorites);
        
        // setFilteredScheduleEntries(filteredFromFavorites);
        // return

        // console.log("Schedule Search", scheduleSearch);
        // console.log("enabledColumns", enabledColumns);
        if((!scheduleSearch || JSON.stringify(scheduleSearch) === JSON.stringify([]) || JSON.stringify(scheduleSearch) === JSON.stringify([""])) && filteredFromFavorites?.length < 1) {
            setFilteredScheduleEntries(scheduleEntries);
            return
        }
        // console.log("Schedule Search", scheduleSearch);
        const monthNameToNumber = {
            "january": "01/",
            "jan": "01/",
            "february": "02/",
            "feb": "02/",
            "march": "03/",
            "mar": "03/",
            "april": "04/",
            "apr": "04/",
            "may": "05/",
            "june": "06/",
            "jun": "06/",
            "july": "07/",
            "jul": "07/",
            "august": "08/",
            "aug": "08/",
            "september": "09/",
            "sep": "09/",
            "october": "10/",
            "oct": "10/",
            "november": "11/",
            "nov": "11/",
            "december": "12/",
            "dec": "12/",
        }
        const filteredArray = filteredFromFavorites?.filter(entry => {
            return scheduleSearch.every((value, index) => {
                // console.log(value);
                if(monthNameToNumber?.hasOwnProperty(value?.toLowerCase())) {
                    value = monthNameToNumber?.[value?.toLowerCase()]
                }
                const searchValue = value?.toLowerCase();
                const checkAgainstValues = Object.entries((entry)).map(([key, checkAgainst]) => {
                    const lowerCaseHeaders = allPossibleColumnsInOrder?.map(header => header?.toLowerCase())
                    if(lowerCaseHeaders?.includes(key?.toLowerCase())) {
                        return checkAgainst.toString()?.replaceAll("’", "'")?.toLowerCase()
                    }
                });

                // console.log("checkAgainstValues", checkAgainstValues, searchValue);
        
                // Regex patterns to match whole words only
                const menPattern = new RegExp("\\bmen\\b");
                const womenPattern = new RegExp("\\bwomen\\b");
                const datePattern = new RegExp("\\b\\d{1,2}/\\d{1,2}/\\d{4}\\b");
        
                // Filter logic for "men" and "women"
                if (menPattern.test(searchValue)) {
                    return checkAgainstValues.some(checkAgainst => {
                        if(!checkAgainst) return false
                        return menPattern.test(checkAgainst) && !womenPattern.test(checkAgainst)
                    }
                    );
                } else if (womenPattern.test(searchValue)) {
                    return checkAgainstValues.some(checkAgainst => {
                        if(!checkAgainst) return false
                        return womenPattern.test(checkAgainst) && !menPattern.test(checkAgainst)
                    }
                    );
                } else {
                    return checkAgainstValues.some(checkAgainst => {
                        if(datePattern.test(checkAgainst)) {
                            return checkAgainst?.startsWith(searchValue)
                        }
                        if(!checkAgainst) return false                        
                        return checkAgainst.includes(searchValue)
                    });
                }
            });
        });
    
        console.log("Filtered Schedule Entries", filteredArray);
        setFilteredScheduleEntries(filteredArray);
    }, [scheduleSearch, scheduleEntries, enabledColumns, allPossibleColumnsInOrder, favoriteEnabledInfo?.isEnabled, scheduleConfiguredFavorites, modalSelectFavoriteInfo?.index]);

    useEffect(() => {
        if(userPreferences?.hasOwnProperty("schedule-is-start-time-12-hr-format")) {
            setIsStartTime12HRFormat(userPreferences["schedule-is-start-time-12-hr-format"])
        }
        if(userPreferences?.hasOwnProperty("schedule-enabled-headers")) {
            setEnabledColumns(userPreferences["schedule-enabled-headers"])
        }
        if(userPreferences?.hasOwnProperty("schedule-columns-order")) {
            setColumnsOrder(userPreferences["schedule-columns-order"])
        }
        if(userPreferences?.hasOwnProperty("schedule-is-row-colors-enabled")) {
            setIsRowColorsEnabled(userPreferences["schedule-is-row-colors-enabled"])
        }
        if(userPreferences?.hasOwnProperty("schedule-configured-favorites")) {
            console.log("schedule-configured-favorites", userPreferences["schedule-configured-favorites"])
            setScheduleConfiguredFavorites(userPreferences["schedule-configured-favorites"])
            setBackupScheduleConfiguredFavorites(userPreferences["schedule-configured-favorites"])
            //* Check only index 0 of favorites currently
            const indexOfEnabledOnLoad = userPreferences["schedule-configured-favorites"]?.findIndex(favorite => favorite?.enabledOnLoad)
            if(indexOfEnabledOnLoad !== -1) {
                setEnabledFavoriteInfo(prev => {
                    return {
                        ...prev,
                        isEnabled: true,
                    }
                })
                setModalSelectFavoriteInfo(prev => {
                    return {
                        ...prev,
                        // isOpen: true,
                        index: indexOfEnabledOnLoad
                    }
                })
            }
        }
    }, [userPreferences])

    useEffect(() => {
        if(!account_info_from_cookie?.username) return
        // console.log("account_info_from_cookie", account_info_from_cookie, account_info_from_cookie?.username, account_info_from_cookie?.username?.split("@")[0])
        fetchUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0])
            .then((data) => {
                setUserPreferences(data)
                // console.log("user preferences", data)
            })
    }, [account_info_from_cookie, localIP])

    useEffect(() => {
        setTimeout(() => {
            if(!maxWidthOverride) return
            try {
                document.querySelector(".cds--data-table-content").style.maxWidth = maxWidthOverride
            } catch (error) {
                console.log(error)
            }
        }, 500)
    }, [maxWidthOverride])

    // useEffect(() => {
    //     console.log("modalEvent", modalEvent)
    // }, [modalEvent])

    const [ws, setWs] = useState(null);
    
    useEffect(() => {
        const websocket = new WebSocket(`wss://${localIP}/socket/playlist`);
      
        websocket.onopen = () => {
          console.log('WebSocket connection opened');
        };

        websocket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                // console.log(data);

                if (data && data.hasOwnProperty("estimate-event-lengths")) {
                    if(data["estimate-event-lengths"]?.length > 0) {
                        setEstimateEventLengths(data["estimate-event-lengths"]);
                    }
                    if(data["current-active-playlist-feed"] !== "") {
                        setCurrentActivePlaylistFeed(data["current-active-playlist-feed"]);
                    }
                    if(data["full-playlist"]?.length > 0) {
                        setFullPlaylistFeed(data["full-playlist"]);
                    }
                }

            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };
      
        websocket.onerror = (error) => {
          console.log(`WebSocket error: ${error}`);
        };
      
        websocket.onclose = () => {
          console.log('WebSocket connection closed');
        };
      
        setWs(websocket);
      
        // Cleanup function
        return () => {
          if (websocket) {
            websocket.close();
          }
        };
    }, []);
      
    //   Send a ping every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send('ping');
            }
        }, 5000);
        
        return () => {
            clearInterval(interval);
        };
    }, [ws]);

    const liraScheduleResultsCountRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setTimeout(() => {
                // console.log("liraScheduleResultsCountRef.current", liraScheduleResultsCountRef.current)
                // console.log(document.getElementById("lira-schedule-main-container").querySelector(".cds--search-input").getBoundingClientRect().right)
                const liraScheduleResultsCount = liraScheduleResultsCountRef.current;
                if (!liraScheduleResultsCount) return;
                liraScheduleResultsCount.style.position = "absolute";
                liraScheduleResultsCount.style.left = document.getElementById("lira-schedule-main-container").querySelector(".cds--search-input").getBoundingClientRect().right - 150 + "px";
            }, 750); // Delay of 750ms to allow DOM rendering
        };

        handleResize(); // Run on component mount

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const [position, setPosition] = useState({ x: 0, y: 0 });

    // const handleDrag = (e) => {
    //     setPosition({ x: e.offsetX, y: e.offsetY });
    // };

    // const handleClose = () => {
    //     setPosition({ x: 0, y: 0 }); // Reset position on close
    //   };

    return (
        <div id="lira-schedule-main-schedule-container" className="relative w-full"
            // style={{ border: currentHoveredPane === mainContainerRef ? "1px solid rgb(255, 255, 255)" : "none" }}
            onMouseOver={() => setCurrentHoveredPane("lira-schedule-main-schedule-container")}
        >
            {displayingCarbonNotificationInfo?.isOpen && <ToastNotification kind="error" role="alert" caption={<span className="text-white">{displayingCarbonNotificationInfo?.message}</span>} timeout={5000} hideCloseButton title={displayingCarbonNotificationInfo?.type} 
                style={{
                    position: "fixed",
                    top: "10px",
                    right: "10px",
                    zIndex: "9999999999",
                    backgroundColor: "rgb(61,61,61)",
                    color: "white",
                    minHeight: "50px"
                }}
                onClose={() => {
                    setDisplayingCarbonNotificationInfo({ isOpen: false, message: null, type: null })
                }}
            />}
            {!disableFullScreenButton && <div id="main-schedule-full-screen-icon" className="absolute grid gap-2 bottom-4 left-4 bg-[rgb(0,0,0,0.4)] z-[999] py-[5px] px-[3px]"
                style={{ gridTemplateColumns: isAssignedScheduleEdit ? "1fr" : "1fr", borderRadius: "5px", boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.9)" }}
            >
                {isMainScheduleFullScreen ? <RxExitFullScreen className="exit-full-screen-icon cursor-pointer w-[35px] h-[35px]"
                        onClick={() => {
                            setIsMainScheduleFullScreen(false)
                        }}
                    /> : <RxEnterFullScreen className="enter-full-screen-icon cursor-pointer w-[35px] h-[35px]"
                        onClick={() => {
                            setIsMainScheduleFullScreen(true)
                        }}
                    />}
            </div>}
            {/* {isAssignedScheduleEdit && <div className="absolute grid gap-2 bottom-4 right-4 bg-[rgb(0,0,0,0.4)] z-[999] py-[5px] px-[3px]"
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", borderRadius: "5px", boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.9)" }}
            >
                {isAssignedScheduleEdit && <Tooltip id="lira-schedule-create-event-tooltip" align="top" label="Create Event"><FaCirclePlus id="create-event-icon" className="cursor-pointer w-[35px] h-[35px]"
                    onClick={() => {
                        setModalEvent({
                            ...DEFAULT_NEW_EVENT_KEY_VALUES,
                            open: true,
                        })
                    }}
                /></Tooltip>}
                {isAssignedScheduleEdit && <Tooltip id="lira-schedule-edit-event-tooltip" align="top" label="Edit Event"><FaPen id="edit-event-icon" className="w-[35px] h-[35px]"
                    style={{ cursor: !selectedScheduleEntry ? "not-allowed" : "pointer", color: !selectedScheduleEntry ? "rgb(100, 100, 100, 0.5)" : "" }}
                    onClick={() => {
                        if(!selectedScheduleEntry) {
                            // alert("Please select an event")
                            displayCarbonNotification("Please select an event", "Error")
                            return
                        }
                        // console.log(selectedScheduleEntry)
                        setModalEvent({
                            ...selectedScheduleEntry,
                            open: true,
                            edit: true,
                        })
                    }}
                /></Tooltip>}
                {isAssignedScheduleEdit && <Tooltip id="lira-schedule-create-duplicate-event-tooltip" align="top" label="Duplicate Event"><IoDuplicate id="duplicate-event-icon" className="w-[35px] h-[35px]"
                    style={{ cursor: !selectedScheduleEntry ? "not-allowed" : "pointer", color: !selectedScheduleEntry ? "rgb(100, 100, 100, 0.5)" : "" }}
                    onClick={() => {
                        if(!selectedScheduleEntry) {
                            // alert("Please select an event")
                            displayCarbonNotification("Please select an event", "Error")
                            return
                        }
                        const { id, createdDateTime, ...rest } = selectedScheduleEntry
                        setModalEvent({
                            ...rest,
                            open: true,
                            duplicate: true,
                        })
                    }}
                /></Tooltip>}
                {isAssignedScheduleEdit && <Tooltip id="lira-schedule-create-delete-event-tooltip" align="top-right" label="Delete Event"><FaTrashAlt id="delete-event-icon" className="w-[35px] h-[35px]"
                    style={{ cursor: !selectedScheduleEntry ? "not-allowed" : "pointer", color: !selectedScheduleEntry ? "rgb(100, 100, 100, 0.5)" : "" }}
                    onClick={() => {
                        if(!selectedScheduleEntry) {
                            // alert("Please select an event")
                            displayCarbonNotification("Please select an event", "Error")
                            return
                        }
                        console.log(selectedScheduleEntry)
                        setModalDeleteEvent({
                            isOpen: true,
                            isDeletingAllFutureRecurrences: false,
                        })
                    }}
                /></Tooltip>}
            </div>} */}
            {scheduleEntries?.length < 1 ? <div className="flex items-center justify-center w-full h-[calc(53vh-3rem)] text-2xl font-bold">No Events</div> : <div id="lira-schedule-main-container" className="hide-scrollbars flex flex-col max-w-[100vw] h-[calc(53vh-3rem)] overflow-hidden">
                <TableToolbar style={{ display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: 1000, position: "relative" }}>
                    <TableToolbarSearch expanded placeholder='Search…'
                        // onClick={() => {
                        //     setSelectedScheduleEntry(null)
                        //     setCurrentEditedCell({ row: null, column: null })
                        // }}
                        onChange={(e) => {
                            handleInputChange(e.target.value)
                        }} 
                        style={{ border: "1px solid grey" }}
                    />
                    {filteredScheduleEntries?.length > 0 && <p ref={liraScheduleResultsCountRef} id="lira-schedule-results-count" className="absolute flex items-center right-[150px] w-24 text-sm text-gray-400">Results: {filteredScheduleEntries?.length}</p>}
                    {/* {!favoriteEnabledInfo?.isEnabled ? <Tooltip id="lira-schedule-overflow-menu-tooltip" align="left" label="Enable Favorite">
                        <OverflowMenu id="lira-schedule-star-overflow-menu" flipped renderIcon={favoriteEnabledInfo?.isEnabled ? IoIosStar : IoIosStarOutline} >
                            <>
                                {scheduleConfiguredFavorites?.map((favorite, index) => {
                                    return (
                                        <OverflowMenuItem itemText={favorite.name || `Favorite ${index+1}`}
                                            onClick={() => {
                                                setModalSelectFavoriteInfo({ isOpen: false, index: index })
                                                setEnabledFavoriteInfo(prev => {
                                                    return {
                                                        ...prev,
                                                        isEnabled: true,
                                                    }
                                                })
                                            }}
                                        />
                                    )
                                })}
                                {scheduleConfiguredFavorites?.length < maxFavoritesPerUser && <OverflowMenuItem itemText={`Add Favorite`}
                                    onClick={() => {
                                        const newPrev = [...scheduleConfiguredFavorites]
                                        newPrev.push({
                                            enabledOnLoad: false,
                                            name: `Favorite ${scheduleConfiguredFavorites?.length + 1}`,
                                            rules: [
                                                {
                                                    header: "Event", rule: "Contains", value: []
                                                }
                                            ]
                                        })
                                        // console.log("new prev", newPrev)
                                        setScheduleConfiguredFavorites(newPrev)
                                        setModalSelectFavoriteInfo({ isOpen: false })
                                        setIsModalConfigureFavoritesInfo({ open: true })
                                    }}
                                />}
                            </>

                        </OverflowMenu>
                    </Tooltip> : <Tooltip id="lira-schedule-overflow-menu-tooltip" align="left" label="Disable Favorite">
                        <IoIosStar id="lira-schedule-star-icon" className="text-yellow-500 cursor-pointer"
                            style={{ width: "40px", height: "25px", marginLeft: "0px", zIndex: "1" }}
                            onClick={() => {
                                setEnabledFavoriteInfo(prev => {
                                    return {
                                        ...prev,
                                        isEnabled: false,
                                    }
                                })
                            }}
                        />
                    </Tooltip>} */}
                    {<Tooltip id="lira-schedule-overflow-menu-tooltip" align="left" label="Schedule Options">
                        <OverflowMenu id="lira-schedule-overflow-menu" flipped renderIcon={IoMdOptions} >
                            {/* <OverflowMenuItem itemText="Configure Favorites"
                                onClick={() => {
                                    setIsModalConfigureFavoritesInfo({ 
                                        "open": true 
                                    })
                                }}
                            />
                            <OverflowMenuItem itemText="Customize Columns"
                                onClick={() => {
                                    setIsModalHeadersOpen({ 
                                        "open": true 
                                    })
                                }}
                            /> */}
                            {/* {isAssignedScheduleFormat && <OverflowMenuItem itemText="Customize Format"
                                onClick={() => {
                                    setScheduleSettingsModalInfo({ 
                                        "open": true 
                                    })
                                }}
                            />} */}
                            <OverflowMenuItem itemText={isRowColorsEnabled ? "Disable Row Colors" : "Enable Row Colors"}
                                onClick={() => {
                                    setIsRowColorsEnabled(!isRowColorsEnabled)
                                    setUserPreferences({ ...userPreferences, "schedule-is-row-colors-enabled": !isRowColorsEnabled })
                                    // postUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0], {
                                    //     ...userPreferences, "schedule-is-row-colors-enabled": !isRowColorsEnabled
                                    // })
                                    // .then(res => {
                                    //     if(res?.hasOwnProperty("error")) {
                                    //         alert(res?.error)
                                    //     }
                                    // })
                                }}
                            />
                            <OverflowMenuItem itemText={isStartTime12HRFormat ? "Enable 24 HR" : "Enable AM/PM"}
                                onClick={() => {
                                    setIsStartTime12HRFormat(!isStartTime12HRFormat)
                                    setUserPreferences({ ...userPreferences, "schedule-is-start-time-12-hr-format": !isStartTime12HRFormat })
                                    // postUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0], {
                                    //     ...userPreferences, "schedule-is-start-time-12-hr-format": !isStartTime12HRFormat
                                    // })
                                    // .then(res => {
                                    //     if(res?.hasOwnProperty("error")) {
                                    //         alert(res?.error)
                                    //     }
                                    // })
                                }}
                            />
                        </OverflowMenu>
                    </Tooltip>}
                </TableToolbar>
                <Table id={"lira-main-schedule-table"} useZebraStyles>
                    <TableHead>
                        <TableRow>
                            {columnsOrder?.map((header, index) => {
                                if(!enabledColumns?.includes(header)) return null
                                return (
                                    <TableHeader
                                        id={`table-header-${header}`}
                                        className="schedule-table-header"
                                        data-header={header?.toLowerCase()}
                                        key={header}
                                        style={{ minWidth: `${header?.toLowerCase()?.length + 3 + NUMBER_THAT_ADDS_SPACE_FOR_SORTING_ARROW_ICON_TO_FIT_WITH_ALL_COLUMNS}ch`, borderRight: "1px solid rgb(100,100,100)", position: "relative" }}
                                    >
                                        {header}
                                        {sortByKey === header?.toLowerCase() && <MdKeyboardArrowDown className="absolute inline-block hover:cursor-pointer right-2 top-2"  style={{ width: "30px", height: "30px", transform: isSortDirectionAscending ? "rotate(0deg)" : "rotate(-180deg)"}}
                                            onClick={() => {
                                                setIsSortDirectionAscending(!isSortDirectionAscending)
                                                setCurrentEditedCell({ key: null, column: null })
                                                setSelectedScheduleEntry(null)
                                            }}
                                        />}
                                    </TableHeader>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody id="table-body">
                        <LIRAScheduleTable
                            arrayForMapping={sortedEntries?.filter(event => {
                                // Set today's date to midnight (00:00:00) for accurate comparison
                                const todaysDate = new Date();
                                todaysDate.setHours(0, 0, 0, 0);
                                
                                // Split the event date string into components
                                const [month, day, year] = event?.date.split("/");
                                // Create a Date object from the event date and set it to midnight
                                const eventDate = new Date(`${year}-${month}-${day}`);
                                eventDate.setDate(eventDate.getDate() + 1)
                                eventDate.setHours(0, 0, 0, 0);
                                // console.log("date check", eventDate, todaysDate)
                                
                                // Compare the event date with today's date
                                return eventDate >= todaysDate;
                            })}
                            fullScheduleSorted={sortedEntries}
                            isRowColorsEnabled={isRowColorsEnabled}
                            colorRules={colorRules}
                            selectedScheduleEntry={selectedScheduleEntry}
                            setSelectedScheduleEntry={setSelectedScheduleEntry}
                            todaysEventsSelectedEvent={todaysEventsSelectedEvent}
                            isAssignedScheduleEdit={isAssignedScheduleEdit}
                            columnsOrder={columnsOrder}
                            enabledColumns={enabledColumns}
                            currentEditedCell={currentEditedCell}
                            setCurrentEditedCell={setCurrentEditedCell}
                            isStartTime12HRFormat={isStartTime12HRFormat}
                            displayCarbonNotification={displayCarbonNotification}
                            formatDate={formatDate}
                            getDayOfWeek={getDayOfWeek}
                            handleEditingEvent={handleEditingEvent}
                            setEditedCellDataWaitingForSaveOrCancel={setEditedCellDataWaitingForSaveOrCancel}
                            newEventPossibleLocations={newEventPossibleLocations}
                            newEventPossibleTypes={newEventPossibleTypes}
                            newEventPossibleCoverages={newEventPossibleCoverages}
                            newEventPossibleEvents={newEventPossibleEvents}
                            newEventPossiblePlatforms={newEventPossiblePlatforms}
                            newEventPossibleEncoders={newEventPossibleEncoders}
                            newEventPossibleProducers={newEventPossibleProducers}
                            newEventPossiblePCRs={newEventPossiblePCRs}
                            newEventPossibleEICs={newEventPossibleEICs}
                            newEventPossibleTMs={newEventPossibleTMs}
                            newEventPossibleORGs={newEventPossibleORGs}
                            newEventPossibleClients={newEventPossibleClients}
                            scheduleSearch={scheduleSearch}
                            prevScheduleSearch={prevScheduleSearch}
                            setPrevScheduleSearch={setPrevScheduleSearch}
                            scrollToFirstEntry={scrollToFirstEntry}
                            setScrollToFirstEntry={setScrollToFirstEntry}
                            hideLogs={false}
                            isRightClickMenuOpen={isRightClickMenuOpen}
                            setIsRightClickMenuOpen={setIsRightClickMenuOpen}
                        />
                    </TableBody>
                </Table>
            </div>}
            <Modal modalHeading="Choose Columns" open={isModalHeadersOpen} primaryButtonText="Save" secondaryButtonText="Cancel" 
                onRequestClose={() => {
                    setIsModalHeadersOpen(false)
                    setEnabledColumns([...userPreferences["schedule-enabled-headers"]] || defaultEnabledColumns)
                    setColumnsOrder([...userPreferences["schedule-columns-order"]] || allPossibleColumnsInOrder)
                }}
                onRequestSubmit={() => {
                    setIsModalHeadersOpen(false)
                    const newPreferences = { ...userPreferences, "schedule-enabled-headers": enabledColumns, "schedule-columns-order": columnsOrder }
                    setUserPreferences({ ...userPreferences, "schedule-enabled-headers": enabledColumns, "schedule-columns-order": columnsOrder })
                    postUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0], newPreferences)
                        .then(res => {
                            if(res?.hasOwnProperty("error")) {
                                alert(res?.error)
                            }
                        })
                }} 
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="flex flex-col items-center justify-center w-full gap-5">
                    <Button kind="secondary"
                        style={{
                            position: "relative",
                            right: "15px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // margin: "0 auto",
                        }}
                        onClick={() => {
                            setEnabledColumns([...defaultEnabledColumns])
                            setColumnsOrder([...allPossibleColumnsInOrder])
                            setUserPreferences({ ...userPreferences, "schedule-enabled-headers": defaultEnabledColumns, "schedule-columns-order": allPossibleColumnsInOrder })
                            postUserPreferences(localIP, account_info_from_cookie?.username?.split("@")[0], {
                                ...userPreferences, "schedule-enabled-headers": defaultEnabledColumns, "schedule-columns-order": allPossibleColumnsInOrder
                            })
                            .then(res => {
                                if(res?.hasOwnProperty("error")) {
                                    alert(res?.error)
                                }
                            })
                        }}
                    >Reset Columns</Button>
                    <DndContext 
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext 
                            items={columnsOrder}
                            strategy={verticalListSortingStrategy}
                        >
                            {columnsOrder?.map(column => <SortableItem 
                                key={column} 
                                id={column} 
                                content={column}
                                allPossibleColumnsInOrder={allPossibleColumnsInOrder}
                                enabledColumns={enabledColumns}
                                setEnabledColumns={setEnabledColumns}
                            />)}
                        </SortableContext>
                    </DndContext>
                </div>
            </Modal>
            {/* <ScheduleSettings 
                colorRules={colorRules} setColorRules={setColorRules}
                newEventPossibleLocations={newEventPossibleLocations}
                newEventPossibleTypes={newEventPossibleTypes}
                newEventPossibleCoverages={newEventPossibleCoverages}
                newEventPossibleEvents={newEventPossibleEvents}
                newEventPossiblePlatforms={newEventPossiblePlatforms}
                newEventPossibleEncoders={newEventPossibleEncoders}
                newEventPossibleProducers={newEventPossibleProducers}
                newEventPossiblePCRs={newEventPossiblePCRs}
                newEventPossibleEICs={newEventPossibleEICs}
                newEventPossibleTMs={newEventPossibleTMs}
                newEventPossibleORGs={newEventPossibleORGs}
                newEventPossibleClients={newEventPossibleClients}
                setNewEventPossibleLocations={setNewEventPossibleLocations}
                setNewEventPossibleTypes={setNewEventPossibleTypes}
                setNewEventPossibleCoverages={setNewEventPossibleCoverages}
                setNewEventPossibleEvents={setNewEventPossibleEvents}
                setNewEventPossiblePlatforms={setNewEventPossiblePlatforms}
                setNewEventPossibleEncoders={setNewEventPossibleEncoders}
                setNewEventPossibleProducers={setNewEventPossibleProducers}
                setNewEventPossiblePCRs={setNewEventPossiblePCRs}
                setNewEventPossibleEICs={setNewEventPossibleEICs}
                setNewEventPossibleTMs={setNewEventPossibleTMs}
                setNewEventPossibleORGs={setNewEventPossibleORGs}
                displayCarbonNotification={displayCarbonNotification}
                setNewEventPossibleClients={setNewEventPossibleClients}
                localIP={localIP} sendServerNewLog={sendServerNewLog}
                account_info_from_cookie={account_info_from_cookie} active_account={active_account}
                isAssignedScheduleEdit={isAssignedScheduleEdit}
                isAssignedScheduleFormat={isAssignedScheduleFormat}
                scheduleSettingsModalInfo={scheduleSettingsModalInfo} setScheduleSettingsModalInfo={setScheduleSettingsModalInfo}  
            /> */}
            <ScheduleConfigureFavorites 
                newEventPossibleLocations={newEventPossibleLocations}
                newEventPossibleTypes={newEventPossibleTypes}
                newEventPossibleCoverages={newEventPossibleCoverages}
                newEventPossibleEvents={newEventPossibleEvents}
                newEventPossiblePlatforms={newEventPossiblePlatforms}
                newEventPossibleEncoders={newEventPossibleEncoders}
                newEventPossibleProducers={newEventPossibleProducers}
                newEventPossiblePCRs={newEventPossiblePCRs}
                newEventPossibleEICs={newEventPossibleEICs}
                newEventPossibleTMs={newEventPossibleTMs}
                newEventPossibleORGs={newEventPossibleORGs}
                newEventPossibleClients={newEventPossibleClients}
                localIP={localIP} sendServerNewLog={sendServerNewLog}
                account_info_from_cookie={account_info_from_cookie} active_account={active_account}
                isModalConfigureFavoritesInfo={isModalConfigureFavoritesInfo} setIsModalConfigureFavoritesInfo={setIsModalConfigureFavoritesInfo}  
                scheduleConfiguredFavorites={scheduleConfiguredFavorites} setScheduleConfiguredFavorites={setScheduleConfiguredFavorites}
                backupScheduleConfiguredFavorites={backupScheduleConfiguredFavorites}
                allPossibleColumnsInOrder={allPossibleColumnsInOrder}
                userPreferences={userPreferences} setUserPreferences={setUserPreferences}
                fetchUserPreferences={fetchUserPreferences} postUserPreferences={postUserPreferences}
            />
            {/* <ScheduleTicketGenerationFields
                ticketGenerationInfo={ticketGenerationInfo} setTicketGenerationInfo={setTicketGenerationInfo} DEFAULT_TICKET_GENERATION_INFO={DEFAULT_TICKET_GENERATION_INFO}
                localIP={localIP} sendServerNewLog={sendServerNewLog}
                account_info_from_cookie={account_info_from_cookie} active_account={active_account}
            /> */}
        </div>
    )
}

function LIRAScheduleTable({
    arrayForMapping,
    fullScheduleSorted=[],
    isRowColorsEnabled,
    colorRules,
    selectedScheduleEntry,
    setSelectedScheduleEntry,
    todaysEventsSelectedEvent,
    isAssignedScheduleEdit=false,
    columnsOrder,
    enabledColumns,
    currentEditedCell,
    setCurrentEditedCell,
    isStartTime12HRFormat,
    displayCarbonNotification,
    formatDate,
    getDayOfWeek,
    handleEditingEvent,
    setEditedCellDataWaitingForSaveOrCancel,
    newEventPossibleLocations,
    newEventPossibleTypes,
    newEventPossibleCoverages,
    newEventPossibleEvents,
    newEventPossiblePlatforms,
    newEventPossibleEncoders,
    newEventPossibleProducers,
    newEventPossiblePCRs,
    newEventPossibleEICs,
    newEventPossibleTMs,
    newEventPossibleORGs,
    newEventPossibleClients,
    scrollToFirstEntry=false,
    setScrollToFirstEntry=() => {},
    scheduleSearch=[],
    prevScheduleSearch=[],
    setPrevScheduleSearch=() => {},
    hideLogs=true,
    isRightClickMenuOpen={
        isOpen: false,
        whichCell: null
    },
    setIsRightClickMenuOpen=() => {},
}) {
    useEffect(() => {
        if(hideLogs) return
        // console.log(scrollToFirstEntry, "search compare", scheduleSearch, prevScheduleSearch)
        if(scrollToFirstEntry 
            || 
            (scheduleSearch?.length === 0 && prevScheduleSearch?.length !== 0)
            ||
            (JSON.stringify(scheduleSearch) === JSON.stringify([""]) && JSON.stringify(prevScheduleSearch) !== JSON.stringify([""]))
            ||
            (JSON.stringify(scheduleSearch) !== JSON.stringify(prevScheduleSearch))
        ) {
            // console.log(scrollToFirstEntry, "search compare", scheduleSearch, prevScheduleSearch)

            setTimeout(() => {
                const indexForScrolling = fullScheduleSorted?.findIndex(event => event?.id === arrayForMapping?.[0]?.id);
                // console.log("array for mapping", indexForScrolling, fullScheduleSorted)
                const elementId = fullScheduleSorted?.[indexForScrolling === 0 ? 0 : indexForScrolling - 1]?.id;
                const element = document?.getElementById(elementId);
                // console.log(document?.getElementById(elementId))
              
                if (element) {
                    if(indexForScrolling === 0) {
                        console.log("scrolling to top")
                        // console.log(document.getElementById("lira-main-schedule-table").parentElement)
                        document.getElementById("lira-main-schedule-table").parentElement.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          })
                        return
                    }
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              
                setScrollToFirstEntry(false);
                setPrevScheduleSearch(scheduleSearch);
              }, 100);
        }
    }, [arrayForMapping, fullScheduleSorted, scheduleSearch, prevScheduleSearch, scrollToFirstEntry, setScrollToFirstEntry, hideLogs, setPrevScheduleSearch])

    return (
        <>
            {fullScheduleSorted?.map((event, index) => {
                // if(index > visibleRows) return null
                let assignedBackgroundColor = ""
                let assignedTextColor = ""
                if(isRowColorsEnabled) {
                    if(event?.coverage?.toLowerCase() === "no") {
                        assignedBackgroundColor = colorRules?.["event-coverage-no"]?.backgroundColor
                        assignedTextColor = colorRules?.["event-coverage-no"]?.color
                    } 
                    else if(event?.coverage?.toLowerCase() === "pend") {
                        assignedBackgroundColor = colorRules?.["event-coverage-pend"]?.backgroundColor
                        assignedTextColor = colorRules?.["event-coverage-pend"]?.color
                    } else if(event?.coverage?.toLowerCase() === "canc") {
                        assignedBackgroundColor = colorRules?.["event-coverage-canc"]?.backgroundColor
                        assignedTextColor = colorRules?.["event-coverage-canc"]?.color
                    } else if(event?.coverage?.toLowerCase() === "psite") {
                        assignedBackgroundColor = colorRules?.["event-coverage-psite"]?.backgroundColor
                        assignedTextColor = colorRules?.["event-coverage-psite"]?.color
                    } else if (event?.coverage?.toLowerCase() === "yes") {
                        if(event?.client?.toLowerCase() === "ncaa") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-ncaa"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-ncaa"]?.color
                        } else if(event?.client?.toLowerCase() === "clas") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-clas"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-clas"]?.color
                        } else if(event?.client?.toLowerCase() === "fc") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-fc"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-fc"]?.color
                        } else if(event?.client?.toLowerCase() === "int") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-int"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-int"]?.color
                        } else if(event?.client?.toLowerCase() === "osd") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-osd"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-osd"]?.color
                        } else if(event?.client?.toLowerCase() === "radio") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-radio"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-radio"]?.color
                        } else if(event?.client?.toLowerCase() === "club") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-club"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-club"]?.color
                        } else if(event?.client?.toLowerCase() === "acad") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-acad"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-acad"]?.color
                        } else if(event?.client?.toLowerCase() === "sa") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-sa"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-sa"]?.color
                        } else if(event?.client?.toLowerCase() === "imag") {
                            assignedBackgroundColor = colorRules?.["event-coverage-yes-client-imag"]?.backgroundColor
                            assignedTextColor = colorRules?.["event-coverage-yes-client-imag"]?.color
                        }
                    }
                }

                return (
                    <TableRow
                        key={event?.id}
                        id={event?.id}
                        className={event?.id === selectedScheduleEntry?.id ? "selected-table-row" : ""}
                        style={{
                            backgroundColor: assignedBackgroundColor,
                            transition: "outline 0.2s ease-in-out",
                            outline: todaysEventsSelectedEvent?.id === event?.id ? "2px solid white" : "none",
                            color: assignedTextColor,
                            cursor: "pointer",
                            // fontWeight: selectedScheduleEntry?.id === event?.id ? "bold" : "normal",
                        }}
                        // onClick={() => {
                        //     if(isAssignedScheduleEdit) {
                        //         console.log("event clicked on", event);
                        //         setSelectedScheduleEntry(event);
                        //     }
                        //     // Ensure the left mouse button was clicked
                        //     // Close the right-click menu on left click
                        //     setIsRightClickMenuOpen({
                        //         isOpen: false,
                        //         whichCell: null
                        //     });
                        // }}
                    >
                        {columnsOrder?.map((header) => {
                            if(!enabledColumns?.includes(header)) return null
                            if(currentEditedCell?.row !== index || currentEditedCell?.column !== header) {
                                if(header?.toLowerCase() === "start time") {
                                    const timeString = event[header?.toLowerCase()];
                                    const [hours, minutes] = timeString.split(':');
                                    const date = new Date();
                                    date.setHours(hours);
                                    date.setMinutes(minutes);
                                
                                    const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
                                    return (
                                        <TableCell className={header} key={`${event?.id}-${header}`} style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch`, borderLeft: "1px solid rgb(80,80,80)", borderBottom: "1px solid rgb(80,80,80)", paddingLeft: "1rem" }}
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     if(!isAssignedScheduleEdit) {
                                            //         console.log("you are not allowed to edit this cell")
                                            //         return
                                            //     }
                                            //     setCurrentEditedCell({ row: index, column: header })
                                            //     setSelectedScheduleEntry(event);
                                            // }}
                                        >
                                            {isStartTime12HRFormat ? formattedTime === "Invalid Date" ? "TBD" : formattedTime : event[header?.toLowerCase()]}
                                        </TableCell>
                                    );
                                } else if(header?.toLowerCase() === "ticket") {
                                    return (
                                        // <>
                                        <TableCell className={header} key={`${event?.id}-${header}`} style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch`, borderLeft: "1px solid rgb(80,80,80)", borderBottom: "1px solid rgb(80,80,80)", paddingLeft: "1rem" }}
                                            onClick={(e) => {
                                                if(isRightClickMenuOpen?.isOpen) {
                                                    setIsRightClickMenuOpen({
                                                        isOpen: false,
                                                        whichCell: null
                                                    });
                                                    return
                                                }
                                                // Handle click for the "ticket" header
                                                if (header?.toLowerCase() === "ticket") {
                                                    if (!event?.[header?.toLowerCase()]) {
                                                        displayCarbonNotification("This event does not have a ticket link calculated", "error");
                                                        return;
                                                    }
                                                    window.open(`http://liberty.service-now.com/nav_to.do?uri=task.do?sysparm_query=number=${event?.[header?.toLowerCase()]}`, "_blank");
                                                    return;
                                                }
                                            
                                                // Prevent editing if not allowed
                                                if (!isAssignedScheduleEdit) {
                                                    console.log("You are not allowed to edit this cell");
                                                    return;
                                                }
                                            
                                                // Skip "day" header handling
                                                if (header?.toLowerCase() === "day") return;
                                            
                                                // Set the current edited cell
                                                setCurrentEditedCell({ row: index, column: header });
                                                setSelectedScheduleEntry(event);
                                            }}
                                            onContextMenu={(e) => {
                                                e.preventDefault();
                                                setSelectedScheduleEntry(event);
                                                setIsRightClickMenuOpen({
                                                    isOpen: true,
                                                    whichCell: "ticket"
                                                });
                                            }}
                                        >
                                            {event[header?.toLowerCase()]}
                                        <Popover open={selectedScheduleEntry?.id === event?.id && isRightClickMenuOpen?.isOpen && isRightClickMenuOpen?.whichCell === "ticket"} align="right"><PopoverContent>
                                            <div className='flex flex-col items-start justify-start gap-2 p-4 cursor-default bg-[rgb(57,57,57)]'
                                                style={{
                                                    width: "18ch",
                                                    height: "auto",
                                                }}
                                            >
                                                <p className="text-gray-400 cursor-pointer hover:text-white"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(event?.[header?.toLowerCase()]);
                                                    }}
                                                >Copy Ticket #</p>
                                                <p className="text-gray-400 cursor-pointer hover:text-white"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`http://liberty.service-now.com/nav_to.do?uri=task.do?sysparm_query=number=${event?.[header?.toLowerCase()]}`);
                                                    }}
                                                >Copy Ticket Link</p>
                                                <p className="text-gray-400 cursor-pointer hover:text-white"
                                                    onClick={() => {
                                                        window.open(`http://liberty.service-now.com/nav_to.do?uri=task.do?sysparm_query=number=${event?.[header?.toLowerCase()]}`, "_blank");
                                                    }}
                                                >Open Ticket</p>
                                            </div>
                                        </PopoverContent></Popover>
                                        </TableCell>
                                        // </>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Platform"].lower) {
                                    return (
                                        <TableCell className={header} key={`${event?.id}-${header}`} style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch`, borderLeft: "1px solid rgb(80,80,80)", borderBottom: "1px solid rgb(80,80,80)", paddingLeft: "1rem" }}
                                            // onClick={(e) => {
                                            //     if(isRightClickMenuOpen?.isOpen) {
                                            //         setIsRightClickMenuOpen({
                                            //             isOpen: false,
                                            //             whichCell: null
                                            //         });
                                            //         return
                                            //     }
                                            //     // Handle click for the "ticket" header
                                            //     if (header?.toLowerCase() === "platform") {
                                            //         if (!event?.["platform url"]) {
                                            //             displayCarbonNotification("This event does not have a platform URL provided", "error");
                                            //             return;
                                            //         }
                                            //         window.open(event?.["platform url"], "_blank");
                                            //         return;
                                            //     }
                                            
                                            //     // Prevent editing if not allowed
                                            //     if (!isAssignedScheduleEdit) {
                                            //         console.log("You are not allowed to edit this cell");
                                            //         return;
                                            //     }
                                            
                                            //     // Set the current edited cell
                                            //     setCurrentEditedCell({ row: index, column: header });
                                            //     setSelectedScheduleEntry(event);
                                            // }}
                                            onContextMenu={(e) => {
                                                e.preventDefault();
                                                setSelectedScheduleEntry(event);
                                                setIsRightClickMenuOpen({
                                                    isOpen: true,
                                                    whichCell: "platform"
                                                });
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textDecoration: event?.["platform url"] ? "underline" : "none",
                                                }}
                                            >{event[header?.toLowerCase()]}</span>
                                        <Popover open={selectedScheduleEntry?.id === event?.id && isRightClickMenuOpen?.isOpen && isRightClickMenuOpen?.whichCell === "platform"} align="right"><PopoverContent>
                                            <div className='flex flex-col items-start justify-start gap-2 p-4 cursor-default bg-[rgb(57,57,57)]'
                                                style={{
                                                    width: "18ch",
                                                    height: "auto",
                                                }}
                                            >
                                                <p className="text-gray-400 cursor-pointer hover:text-white"
                                                    onClick={() => {
                                                        if (!event?.["platform url"]) {
                                                            displayCarbonNotification("This event does not have a platform URL provided", "error");
                                                            return;
                                                        }
                                                        navigator.clipboard.writeText(event?.["platform url"]);
                                                    }}
                                                >Copy URL</p>
                                                <p className="text-gray-400 cursor-pointer hover:text-white"
                                                    onClick={() => {
                                                        if (!event?.["platform url"]) {
                                                            displayCarbonNotification("This event does not have a platform URL provided", "error");
                                                            return;
                                                        }
                                                        window.open(event?.["platform url"], "_blank");
                                                    }}
                                                >Open URL</p>
                                            </div>
                                        </PopoverContent></Popover>
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell className={header} key={`${event?.id}-${header}`} style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch`, borderLeft: "1px solid rgb(80,80,80)", borderBottom: "1px solid rgb(80,80,80)", paddingLeft: "1rem" }}
                                            // onClick={() => {
                                            //     if(header?.toLowerCase() === "ticket" || header?.toLowerCase() === "platform") return
                                            //     if(!isAssignedScheduleEdit) {
                                            //         console.log("you are not allowed to edit this cell")
                                            //         return
                                            //     }
                                            //     if(header?.toLowerCase() === "day") return
                                            //     setCurrentEditedCell({ row: index, column: header })
                                            //     setSelectedScheduleEntry(event);
                                            // }}
                                        >
                                            {event[header?.toLowerCase()]}
                                        </TableCell>
                                    )
                                }
                            } else if(isAssignedScheduleEdit && currentEditedCell?.row === index && currentEditedCell?.column === header) {
                                if(header?.toLowerCase() === "date") {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`} className="cell-in-editing">
                                                <DatePicker datePickerType="single"
                                                    onChange={(value) => {
                                                        if(!value) return
                                                        // console.log("changed", value)
                                                        const dateString = formatDate(value)
                                                        const dayOfWeek = getDayOfWeek(dateString)
                                                        // console.log(dateString)
                                                        if(!dateString || dateString === "aN/aN/NaN") return
                                                        handleEditingEvent({ ...event, [header?.toLowerCase()]: dateString, day: dayOfWeek })
                                                    }}
                                                >
                                                    <DatePickerInput className="test-for-focus" placeholder="mm/dd/yyyy" defaultValue={event?.date || ""} labelText="" size="md" />
                                                </DatePicker>
                                        </TableCell>
                                    )
                                } 
                                else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Start Time"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <TextInput autoFocus 
                                                className="cell-in-editing"
                                                type="time" labelText="" placeholder="Event Start Time" 
                                                defaultValue={event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Start Time"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: index, column: header })
                                                    setEditedCellDataWaitingForSaveOrCancel({ ...selectedScheduleEntry, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            />
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Event"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Event"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleEvents?.map((event, index) => {
                                                    if(!event?.isVisible) return null
                                                    return <SelectItem key={index} text={event?.label} value={event?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Location"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Location"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleLocations?.map((location, index) => {
                                                    if(!location?.isVisible) return null
                                                    return <SelectItem key={index} text={location?.label} value={location?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["PCR"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["PCR"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.replace(" ", "_")?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossiblePCRs?.map((pcr, index) => {
                                                    if(!pcr?.isVisible) return null
                                                    return <SelectItem key={index} text={pcr?.label} value={pcr?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Encoder"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Encoder"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.replace(" ", "_")?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleEncoders?.map((encoder, index) => {
                                                    if(!encoder?.isVisible) return null
                                                    return <SelectItem key={index} text={encoder?.label} value={encoder?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Producer"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Producer"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}>
                                                {newEventPossibleProducers.map((producer, index) => {
                                                    if(!producer?.isVisible) return null
                                                    return <SelectItem key={index} text={producer?.label} value={producer?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["EIC"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["EIC"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    console.log("EIC", e.target.value)
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleEICs?.map((eic, index) => {
                                                    if(!eic?.isVisible) return null
                                                    return <SelectItem key={index} text={eic?.label} value={eic?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Field Engineer"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Field Engineer"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleEICs?.map((eic, index) => {
                                                    if(!eic?.isVisible) return null
                                                    return <SelectItem key={index} text={eic?.label} value={eic?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Tech Manager"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Tech Manager"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleTMs?.map((tm, index) => {
                                                    if(!tm?.isVisible) return null
                                                    return <SelectItem key={index} text={tm?.label} value={tm?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Coverage"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Coverage"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleCoverages?.map((coverage, index) => {
                                                    if(!coverage?.isVisible) return null
                                                    return <SelectItem key={index} text={coverage?.label} value={coverage?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["ORG"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["ORG"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleORGs?.map((org, index) => {
                                                    if(!org?.isVisible) return null
                                                    return <SelectItem key={index} text={org?.label} value={org?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Client"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Client"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleClients?.map((client, index) => {
                                                    if(!client?.isVisible) return null
                                                    return <SelectItem key={index} text={client?.label} value={client?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else if(header?.toLowerCase() === COLUMN_VALUES_CASE_SENSITIVE["Type"].lower) {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <Select id={`${event?.id}-${header}`} className="cell-in-editing" defaultValue={event?.[header?.toLowerCase()] === "" ? "TBD" : event?.[COLUMN_VALUES_CASE_SENSITIVE?.["Type"].lower]} 
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: null, column: null })
                                                    handleEditingEvent({ ...event, [header?.toLowerCase()]: e.target.value })
                                                }}
                                            >
                                                {newEventPossibleTypes?.map((type, index) => {
                                                    if(!type?.isVisible) return null
                                                    return <SelectItem key={index} text={type?.label} value={type?.label} />
                                                })}
                                            </Select>
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell key={`${event?.id}-${header}`}>
                                            <TextInput
                                                autoFocus
                                                className="cell-in-editing"
                                                type="text"
                                                defaultValue={event[header?.toLowerCase()]}
                                                style={{ minWidth: `${event[header?.toLowerCase()]?.length + 4}ch` }}
                                                onChange={(e) => {
                                                    setCurrentEditedCell({ row: index, column: header })
                                                    setEditedCellDataWaitingForSaveOrCancel({ ...selectedScheduleEntry, [header?.toLowerCase()]: e.target.value })
                                                    // console.log("Value", e.target.value)
                                                }}
                                            />
                                        </TableCell>
                                    )
                                }
                            }
                        })}
                    </TableRow>
                )
            })}
        </>
    )
}

export function SortableItem({
    id,
    content,
    allPossibleColumnsInOrder,
    enabledColumns,
    setEnabledColumns
}) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
        <div className="flex items-center justify-center w-full" style={style}>
            <Button kind="secondary"
                style={{
                    backgroundColor: enabledColumns?.includes(content) ? "forestGreen" : "",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // margin: "0 auto",
                }}
                onClick={() => {
                    if (!enabledColumns?.includes(content)) {
                        const tempArray = [...enabledColumns]
                        // console.log(tempArray)
                        tempArray.push(content)
                        const filteredEnabledColumns = allPossibleColumnsInOrder.filter(checkAgainstHeader => tempArray?.includes(checkAgainstHeader));
                        setEnabledColumns(filteredEnabledColumns)
                    } else {
                        setEnabledColumns(enabledColumns?.filter((columnName) => columnName !== content))
                    }
                }}
            >
                {content}
            </Button>
            <div className="relative grid right-8" ref={setNodeRef} {...attributes} {...listeners}>
                <RiDraggable style={{ width: "30px", height: "30px" }}/>
            </div>
        </div>

    );
  }

function ScheduleChooseFromListOfFavorites({
    scheduleConfiguredFavorites,
    setScheduleConfiguredFavorites,
    setIsModalConfigureFavoritesInfo,
    modalSelectFavoriteInfo,
    setModalSelectFavoriteInfo,
    setEnabledFavoriteInfo,
}) {
    const maxFavoritesPerUser = 3

    return (
        <Modal 
            open={modalSelectFavoriteInfo?.isOpen} 
            // open={true} 
            passiveModal
            onRequestClose={() => setModalSelectFavoriteInfo({ isOpen: false, index: null })}
        >
            <div className="flex flex-col items-center justify-center gap-4">
                <h2 className="text-2xl">Choose From Your Favorites</h2>
                <div className="flex flex-col gap-2">
                    {scheduleConfiguredFavorites?.map((favorite, index) => {
                        return (
                            <div key={index}>
                                <Button kind="secondary"
                                    style={{ width: "250px"}}
                                    onClick={() => {
                                        setModalSelectFavoriteInfo({ isOpen: false, index: index })
                                        setEnabledFavoriteInfo(prev => {
                                            return {
                                                ...prev,
                                                isEnabled: true,
                                            }
                                        })
                                    }}
                                >{favorite?.name}</Button>
                            </div>
                        )
                    })}
                    <Button kind="secondary"
                        disabled={scheduleConfiguredFavorites?.length >= maxFavoritesPerUser}
                        style={{ width: "250px"}}
                        onClick={() => {
                            const newPrev = [...scheduleConfiguredFavorites]
                            newPrev.push({
                                enabledOnLoad: false,
                                name: `Favorite ${scheduleConfiguredFavorites?.length + 1}`,
                                rules: [
                                    // {
                                    //     header: "Name", rule: "Contains", value: []
                                    // }
                                ]
                            })
                            // console.log("new prev", newPrev)
                            setScheduleConfiguredFavorites(newPrev)
                            setModalSelectFavoriteInfo({ isOpen: false })
                            setIsModalConfigureFavoritesInfo({ open: true })
                        }}
                    >{scheduleConfiguredFavorites?.length < maxFavoritesPerUser ? `Add New Favorite ${scheduleConfiguredFavorites.length}/${maxFavoritesPerUser}` : `Maxed at ${maxFavoritesPerUser} favorites`}</Button>
                </div>
            </div>
        </Modal>
    )
}